* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-size: 1.6rem;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--color-white);
  background-image: url(../../assets/img/backgroud/bg-body.svg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  &::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background-color: var(--color-white-500);        /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #211916;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #211916;  /* creates padding around scroll thumb */
  }
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

}
small{
  font-size: 1.1rem;
}
img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  &[disabled] {
    cursor: not-allowed;
  }
}
::selection {
  color: var(--color-white);
  background: #0F7BA6;
}

