
.form-registro{
  .conteudo-registro{
    @include breakpoint($celular){
      order: 2;
    }
  }
  .sidebar-registro{
    @include breakpoint($celular){
      order: 1;
      position: inherit!important;
    }
  }
}


.sidebar-registro{
  .box-sidebar-person{
    h3{
      color: #1E2340;
      font-size: 2.2rem;
      font-weight: 600;
    }
    .content-person{
      display: flex;
      padding: 0rem 1rem;
      align-items: center;
      gap: 1rem;
      align-self: stretch;
    }

  }
}

.btn-add-person{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border:solid 2px #D9D9D9;
  img{
    padding: 13px;
    border-radius: 50%;
    background-color: #D9D9D9;
  }
  &:hover{
    opacity: 0.7;
  }
  &.btn-gg{
    width: 6rem;
    height: 6rem;
    img{
      padding: 13px;
      border-radius: 50%;
      background-color: #D9D9D9;
    }
  }
  &.btn-mm{
    width: 3.2rem;
    height: 3.2rem;
    img{
      width: 82%;
      padding: 4px;
      border-radius: 50%;
      background-color: #D9D9D9;
    }
  }
}
progress{
  width: 100%;;
  box-sizing: border-box;
	border: solid 1px #E6E6E6;
  height: 1rem;
	border-radius: 23px;
  &[value] {
    border-radius: 23px!important;
    border-top-left-radius: 50%;
    width:              100%;
    margin:             5px 0;
    height:             1rem;
    display:            block;
    appearance:         none;
    -webkit-appearance: none;
    &::-moz-progress-bar {
      border-radius: 12px;
      background-color: var(--color-white);
      background-color: #089247;
    }
    &::-webkit-progress-bar {
      background-color: var( --color-white-700);
    }
    &::-webkit-progress-value {
      border-radius: 12px;
      background-color: var(--color-white);
      background-color: #089247;
    }
  }
}


.form-registro{
  .conteudo-select{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label{
      color: var(--color-black);
      font-size: 1.4rem;
      font-weight: 600;
    }
    select{
      border-radius: 4px;
      border: 1px solid var(--color-gray-200);
      background: var(--color-white);
      display: flex;
      height: 4rem;
      padding: 0.4rem;
      font-size: 1.4rem;
      align-items: center;
      gap: 0.8rem;
      align-self: stretch;
      color: #999;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      option{
        &:nth-child(1){
          // color:red;
        }
      }
    }
  }
  .conteudo-textarea{
    margin-bottom: 10px;
    label{
      color: var(--pure-pure-100, #000);
      font-size: 1.4rem;
      font-weight: 600;
    }
    textarea{
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--color-gray-200);
      background: var(--color-white);
      font-size: 1.4rem;
    }
    p{
      width: 100%;
      text-align: right;
    }
  }
  .input-upload{
    input[type="file"]{
      border: 1px solid #C4C4C4;
      background: #FFF;
      border-radius: 122px;
      width: 100%;
      max-width: 67.8rem;
      height: 5.6rem;
      line-height: 5.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-repeat: no-repeat;
      background-position: left 20px center;
      &::before {
        content: '';
      }
      &::-webkit-file-upload-button {
        visibility: hidden;
      }
    }
  }
  .container-btns{
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    margin-top: 0.5rem;
    input{
      width: 100%;
      cursor: pointer;
      @include breakpoint($celular){
        max-width: 300px;
      }
      &:disabled{
        opacity: 0.5;
        cursor:no-drop;
      }
    }
  }
}
