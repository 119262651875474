
.page-chamado{
  .sidebar-chamado{
    @include breakpoint($celular){
      order: 2;
    }
  }
  .conteudo-chamado{
    @include breakpoint($celular){
      order: 1;
    }
  }
}

.header-page-voltar{
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.sidebar-chamado{
  top: 5%;
  max-width: 384px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
  @include breakpoint($tablet){
      max-width: 250px;
  }
  @include breakpoint($celular){
      max-width: 100%;
  }
}


.header-chamado{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint($celular){
    flex-direction: column;
  }
  .filtro-time-line{
    gap: 1rem;
    align-items: center;
    @include breakpoint($celular){
      width: 100%;
    }
  }
  .conteudo-search{
    border: none!important;
    width: 100%;
    max-width: 47.8rem;
    height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    input[type=text]{
      background-color: rgb(255, 255, 255);
      width: 80%;
      height: 80%;
      border: 0;
      outline: none;
      font-size: 1.5rem;
      line-height: 150%;
      color: var(--color-black-800);
      padding-left: 1.5rem;
      padding-right: 0.7rem;
      display: none;
      border-bottom: solid 2px #000;
      &.active{
        display: block;
      }
    }
    button {
      width: 42px;
      height: 42px;
      background-color: var(--color-gray-100);
      border-radius: 50%;
      border: 1px solid transparent;
      transition: border-color .3s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      text-indent: -9999px;
      &:hover {
        border-color: var(--color-black-800);
      }
    }
  }
}

.conteudo-chamado{
  .header-perfil-chamado{
    display: flex;
    padding: 1.8rem 2rem 0rem 2rem;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    .perfil-aluno-chamado{
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      .perfil-aluno{
        width: 6.9rem;
        height: 7.1rem;
        position: relative;
        @include breakpoint($celular){
          width: 82px;
          height: 71px;
        }
        &.aberto{
          &::after{
            background-color: var(--color-gray-300);
          }
        }
        &.pendente{
          &::after{
            background-color: var(--color-yellow-100);
          }
        }
        &.aguardando-agendamentoerto{
          &::after{
            background-color: var(--color-yellow);
          }
        }
        &.agendado{
          &::after{
            background-color: var(--color-blue-800);
          }
        }
        &.atendido{
          &::after{
            background-color: var(--color-green);
          }
        }
        &.encerrado{
          &::after{
            background-color: var(--color-green-500);
          }
        }
        &.encaminhado{
          &::after{
            background-color: var(--color-red);
          }

        }
        &.resolvido{
          &::after{
            background-color: var(--color-green);
          }
        }
        &.nao-visualizado{
          &::after{
            background-color: var(--color-gray-300);
          }
        }
        &::after{
            position: absolute;
            bottom: -1px;
            left: 0;
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            border: 4px solid var(--color-white);
            background-color: var(--color-gray-100);
        }
        span{
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color:var(--color-gray-200);
          color: var(--color-white);
          font-weight: bold;
          border-radius: 50%;
        }
        img{
          border-radius: 50%;
          width: 100%;
        }
      }
      .infor-aluno-chamado{
        .nome-aluno{
          color: #000;
          font-size: 1.6rem;
          font-weight: 600;
          letter-spacing: -0.024rem;
        }
        .descricao{
          color: #000;
          font-size: 1.2rem;
          line-height: 2rem;
        }
      }
    }
    .card-opcoes-chamado{
      display: none;
      &:checked{
        &~ label .opcoes-card{
            display: none;
        }
        &~ label{
            .btn-opcoes{
                opacity: 0.5;
            }
        }
      }
    }
    label{
      position: relative;
      .opcoes-card{
        position: absolute;
        top: 40px;
        right: 5px;
        display: inline-flex;
        padding: 5px 10px;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        background-color: var(--color-white);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 90;

        a{
            color: var(--color-black-900);
            font-size: 1.5rem;
        }
      }
      .btn-opcoes{
        width: 23px;
        height: 23px;
        display: block;
        background-image: url(../../assets/img/icons/icon-option-black.svg);
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 90;
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .tab-status{
    display: flex;
    padding: 1.2rem 3rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #DDD;
    .status{
      color: var(--color-gray-500);
      font-size: 1rem;
      height: 4.2rem;
      background-image: url(../../assets/img/icons/btn-status.svg);
      background-position: bottom center;
      background-repeat: no-repeat;
      &.ativo{
        background-image: url(../../assets/img/icons/btn-status-avito.svg);
      }
    }
    .opcoes-encaminhamentos{
      p{
        color: var(--color-gray-500);
        font-size: 1rem;
      }
      .encaminhamentos{
        display: flex;
        align-items: center;
        gap: 2rem;
        &__psc{
          background-image: url(../../assets/img/icons/btn-psc.svg);
          background-position: bottom center;
          background-repeat: no-repeat;
          height: 3.0rem;
          width: 4.2rem;
          display: block;
          &:hover{
            background-image: url(../../assets/img/icons/btn-psc-ativo.svg);
          }
          &.ativo{
            background-image: url(../../assets/img/icons/btn-psc-ativo.svg);
          }
        }
        &__police{
          background-image: url(../../assets/img/icons/btn-police.svg);
          background-position: bottom center;
          background-repeat: no-repeat;
          height: 3.0rem;
          width: 4.2rem;
          display: block;
          &:hover{
            background-image: url(../../assets/img/icons/btn-police-ativo.svg);
          }
          &.ativo{
            background-image: url(../../assets/img/icons/btn-police-ativo.svg);
          }
        }
        &__obs{
          background-image: url(../../assets/img/icons/btn-hosp.svg);
          background-position: bottom center;
          background-repeat: no-repeat;
          height: 3.0rem;
          width: 4.2rem;
          display: block;
          &:hover{
            background-image: url(../../assets/img/icons/btn-hosp-ativo.svg);
          }
          &.ativo{
            background-image: url(../../assets/img/icons/btn-hosp-ativo.svg);
          }
        }
      }
    }
  }

}


.input-upload{
  width: 100%;
  form{
        // border-radius: 63px;
    // border: 1px solid #C4C4C4;
    // background: #FFF;
    // border-radius: 122px;
    width: 100%;
    max-width: 67.8rem;
    // height: 5.6rem;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    textarea{
      width: 100%;
      border: 1px solid #C4C4C4!important;
      border-radius: 6px;
      padding: 10px;
    }
    .tox-tinymce{
      width: 100%;
      border: 1px solid #C4C4C4!important;
      border-radius: 6px;
    }
    // border-radius: 63px;
    // border: 1px solid #C4C4C4;
    // background: #FFF;
    // border-radius: 122px;
    // width: 100%;
    // max-width: 67.8rem;
    // height: 5.6rem;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    input[type="file"]{
      font-size: 1.5rem;
      width: 100%;
      border: 1px solid #C4C4C4!important;
      border-radius: 6px;
      padding-left: 0.8rem;
      padding-right: 0.7rem;
      cursor: pointer;
      background: #FFF;
      height: 100%;
      outline: none;
      line-height: 38px;
      // &::before {
      //   content: '';
      // }
      // &::-webkit-file-upload-button {
      //   visibility: hidden;
      // }
    }

    .btn-upload{
      width: 42px;
      height: 42px;
      background-color: var(--color-blue);
      border-radius: 50%;
      border: 1px solid transparent;
      transition: border-color .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: 10px;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
      background-image: url(../../assets/img/icons/btn-send.svg);
      &:hover{
        background-color: var(--color-blue-600);
      }
    }
  }
}


.chamado-historico{
  ul{
    width: 100%;
    max-width: 580px;
    counter-reset: titles;
    position: relative;
    &::before{
      position: absolute;
      content: "";
      height: 100%;
      width: 0.1rem;
      top: 0;
      left: 6.0rem;
      background-color: var(--color-gray-300);
    }
    li{
      display: block;
      padding: 20px 20px;
      position: relative;
      padding-left: 80px;
      &:last-child{
        &:after{
          display: none!important;
        }
      }
      &::before{
        counter-increment: titles;
        content: counter(titles);
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        border: 1px solid #0F7BA6;
        background: var(--color-white);
        position:absolute;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #0F7BA6;
        text-align: center;
        font-size: 2.419rem;
        font-weight: 500;
      }
      &::after{
        content: "";
        width: 0.1rem;
        border-left: solid 1px var(--color-gray-300);
        height: 38px;
        position: absolute;
        left: 21px;
        bottom: -24px;
      }
      p{
        color: var( --color-black);
        font-size: 1.4rem;
        font-weight: 600;
        small{
          font-weight: 400;
          color: var(--color-gray-800);
          font-size: 1.167rem;
        }
      }
    }
  }
}
