.btn_primary{
  padding: 12px 24px;
  // width: 256.18px;
  // height: 42px;
  background-color: var(--color-blue);
  border-radius: 2.0rem;;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.0rem;
  text-align: center;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:10px;
  align-items: center;
  &:hover{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), var(--color-blue);
  }
  &[disabled]{
    opacity: 0.45!important;
  }
}
.btn_outline{
  color: var(--color-blue);
  text-align: center;
  font-size: 1.68rem;
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid var(--color-blue);
  background-color:  var(--color-white);
  padding: 0.8rem 2rem;
  &:hover{
      background-color: var(--color-blue);
      color:  var(--color-white);
  }
}

.btn-voltar-page{
  display: flex;
  width: 5rem;
  height: 5rem;
  // transform: rotate(-90deg);
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 67px;
  background-color:var(--color-blue);
  margin: 23px 0 53px 0;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
  i{
    font-size: 2.5rem;
    color: var(--color-white);
  }
}

.btn-action{
  display: flex;
  color: var(--color-blue);
  width: 35rem;
  height: 5rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 1.68rem;
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid var(--color-blue);
  background-color:  var(--color-white);
  padding: 0.8rem 2rem;
  &:hover{
      background-color: var(--color-blue);
      color:  var(--color-white);
  }
}

.btn-alterar-senha{
  display: flex;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  color: #1565D8;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration-line: underline;
  text-transform: capitalize;
  margin: 1rem 0;
  img{
    width: 2.2rem;
    height: 2.2rem
  }
  &:hover{
    opacity: 0.8;
  }
}

.btn_gov_br{
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.0rem;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  width: 30.4rem;
  height: 3.9rem;
  padding: 0.8rem 2.4rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border-radius: 1.25rem;
  background: #1351B4;
  &:hover{
    background-image: linear-gradient(rgba(255,255,255, 0.3), rgba(255,255,255, 0.3));
  }
}
