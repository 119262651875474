/* Grid Containers */
.container {
  width: 100%;
  max-width: 1246px;
  margin: 0 auto;
  padding: 0px 15px;
}
.container-fluid {
  width: 100%;
  padding: 0px 15px;
}

.container-conviva{
  width: 100%;
  max-width: 1008px;
  margin: 0 auto;
  padding: 0px 15px;
}

.conteudo-r{
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  justify-content: flex-end;
}
.conteudo-l{
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  justify-content: flex-start;
}
.conteudo-c{
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  justify-content: center;
}


.progress-bar {
  height: 0.3rem;
  background: #221a17;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
.hidden {
  opacity: 0%!important;
}
.back-to-top {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border-radius: 100%;
  background-color: var(--color-black-100);
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s;
  z-index: 1;
  width: 4rem;
  height: 4rem;
  &:hover{
    opacity: 60%;
  }
  .back-to-top-icon {
    width: 3rem;
    height: 3rem;
    color: var(--color-white-500);
  }
}


