
.page-perfil{
  .conteudo-perfil{
    @include breakpoint($celular){
      order: 2;
    }
  }
  .sidebar{
    @include breakpoint($celular){
      position: inherit!important;
      order: 1;
    }
    .links-acesso-rapido{
      @include breakpoint($celular){
        display: none;
      }
    }
  }
  .sidebar-aluno{
    @include breakpoint($celular){
      position: inherit!important;
      order: 1;
    }
    .links-acesso-rapido{
        @include breakpoint($celular){
          display: none;
      }
    }
  }
}

.conteudo-perfil{
  .conteudo-casos{
    display: flex;
    padding: 0rem 0rem 1.5rem 0rem;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    .box-casos{
      display: flex;
      height: 8.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      border-radius: 11.644px;
      &:hover{
        opacity: 0.7;
      }
      &.abertos{
        border: 0.5px solid var(--color-red);
        background-color: var(--color-red-100);
      }
      &.criados{
        border: 0.5px solid var(--color-blue);
        background-color: var(--color-blue-100);
      }
      &.encerrados{
        border: 0.5px solid var(--color-green);
        background-color: var(--color-green-100);
      }
      .number-casos{
        color: #596E79;
        text-align: center;
        font-size: 2.3288rem;
        font-weight: 600;
        line-height: 2.5616rem;
      }
      .legenda-casos{
        color: var(--color-gray-600);
        text-align: center;
        font-size: 1.4rem
      }
    }
  }

  .escola-perfil{
    border-bottom: 1px solid #E2E2E2;
    padding: 0rem 0rem 1.5rem 0rem;
    margin-bottom: 1.5rem;
    h3{
      color: var(--color-blue);
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    .row-escola{
      display: flex;
      align-items: center;
      gap: 1rem;
      align-self: stretch;
      p{
        color: #596E79;
        font-size: 1.5rem;
      }
    }
  }

  .dados-pessoais{
    h3{
      color: var(--color-blue);
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    p{
      color: var(--text-default, #606878);
      b{
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}


.identificacao-perfil{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  .foto-perfil{
    width: 14.55rem;
    height: 19.7rem;
    position: relative;
    span{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 35px;
      background-color:var(--color-gray-200);
      color: var(--color-white);
      font-weight: bold;
      border-radius: 50%;
    }
    img{
      border-radius: 50%;
      width: 100%;
    }
    .icon-atualizar-photo{
      display: flex;
      width: 2.7rem;
      height: 2.7rem;
      padding: 0.6rem 0.6rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      position: absolute;
      right: 2rem;
      bottom: 0rem;
      border-radius: 50%;
      background-color: #C7C7C7;
      img{
        width: 90%;
      }
      &:hover{
        opacity: 0.8;
      }
    }
  }
  .iniciais-perfil{
    width: 14.55rem;
    height: 14.55rem;
    position: relative;
    span{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 35px;
      background-color: var(--color-gray-200);
      color: var(--color-white);
      font-weight: bold;
      border-radius: 50%;
    }
    img{
      border-radius: 50%;
      width: 100%;
    }
    .icon-atualizar-photo{
      display: flex;
      width: 2.7rem;
      height: 2.7rem;
      padding: 0.6rem 0.6rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      position: absolute;
      right: 2rem;
      bottom: 0rem;
      border-radius: 50%;
      background-color: #C7C7C7;
      img{
        width: 90%;
      }
      &:hover{
        opacity: 0.8;
      }
    }
  }
  .nome-perfil{
    color: #1E2340;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.2rem;
  }
  .cargo-perfil{
    color: #596E79;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.2rem;
  }
  .data-perfil{
    color: #B3BAC5;
    text-align: center;
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
}


.sidebar-aluno{
  height: auto!important;
  .identificacao-perfil{
    .foto-perfil{
      border: 3px solid #596E79;
      border-radius: 50%;
      padding: 3px;
      margin-bottom: 6px;
      &.alerta{
        border-color: #F25C05;
      }
      &.atencao{
          border-color:#EBFF00;
      }
    }
    .iniciais-perfil{
      border: 3px solid #596E79;
      border-radius: 50%;
      padding: 3px;
      margin-bottom: 6px;
      &.alerta{
        border-color: #F25C05;
      }
      &.atencao{
          border-color:#EBFF00;
      }
    }
  }
}

.conteudo-btn-acoes{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.conteudo-btn-opcoes{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  margin-top: 10px;
}

.btn-perfil-voltar{
  cursor: pointer;
}
