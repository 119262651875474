@import "../../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import './grid';
@import './colors';
@import './reset';
@import './login';
@import './btn';
@import './header';
@import './variables';
@import './filtro';
@import './card';
@import './home';
@import './carrosel';
@import './sidebar';
@import './chamados';
@import './notificacoes';
@import './perfil';
@import './modal';
@import './registro';
@import './buscar_aluno';
@import './visitas';
@import './darkmode';
@import './tabulator';

// .star-rating i {
//   cursor: pointer;
//   color: #ccc;
//   font-size: 22px;
//   margin-right: 2PX;
//   transition: color 0.2s;
// }
// .star-rating i{
//   &:hover{
//     &:nth-child(1){
//       color: #FF0000!important;
//       font-weight: 900;
//     }
//     &:nth-child(2) ~ &:nth-child(1){
//       color: #FF0000!important;
//       font-weight: 900;
//     }
//     &:nth-child(3){
//       color: #ffee04!important;
//       font-weight: 900;
//     }
//     &:nth-child(4){
//       color: #0F7BA6!important;
//       font-weight: 900;
//     }
//     &:nth-child(5){
//       color: #0F7BA6!important;
//       font-weight: 900;
//     }
//   }
// }

.star-rating {
  display: inline-block;

  i {
    cursor: pointer;
    color: #ccc;
    font-size: 20px;
    margin-right: 2PX;
    transition: color 0.2s;
  }

  i.hover-on:nth-child(5):hover{
    color: #0F7BA6 !important;
    font-weight: 900;
  }

  .hover-on:nth-child(4):hover{
    color: #0F7BA6 !important;
    font-weight: 900;
  }

  .hover-on:nth-child(3):hover{
    color: #ffee04 !important;
    font-weight: 900;
  }

  .hover-on:nth-child(2):hover{
    color: #FF0000 !important;
    font-weight: 900;
  }

  .hover-on:nth-child(1):hover,
  .hover-on:nth-child(2):hover ~ i:nth-child(1) {
    color: #FF0000 !important;
    font-weight: 900;
  }
}


.overlay{
position: relative;
&::before{
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 55%);
  z-index: 9998;
}
}
header{
    .container-fluid{
        background-color: #181818;
        h1{
            padding: 20px 0;
            text-align: center;
            color: #FFF;
        }
    }
}

.sub-menu {
    display: none;
    list-style: none;
    padding-left: 20px;
  }

  .sub-menu.active {
    display: block;
  }
