/* Importing ng select SCSS file. */
@import "~@ng-select/ng-select/themes/default.theme.css";


/* Importing fonts google. */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

/* Importing swiper SCSS file */
@import '~swiper/swiper-bundle.css';

/* Importing Bootstrap SCSS file. */

// Configuration
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

// Layout & components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/pagination";

.pagination-lg {
    --bs-pagination-padding-x: 1.5rem !important;
    --bs-pagination-padding-y: 0.75rem !important;
    --bs-pagination-font-size: 1.6rem !important;
    --bs-pagination-border-radius: var(--bs-border-radius-lg) !important;
}

// Helpers
@import "node_modules/bootstrap/scss/helpers";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";

/* End Bootstrap SCSS file. */

/* Importing main SCSS file */
@import "./app/scss/main.scss";
