.links-acesso-rapido{
  display: flex;
  padding: 0.8rem 1.6rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;
  background: #F6F6F6;
  box-shadow: 0px 1px 6px 0px rgba(51, 51, 51, 0.08);
  h3{
      color:  #000;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.932rem;
  }
  h5{
      font-weight: 400;
      color: #757474;
      font-size: 1.167rem;
      line-height: 1.5rem;
  }
  ul{
      display: flex;
      padding-top: 0.8rem;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      li{
          a{
              display: block;
              border-radius: 8px;
              padding: 0.8rem;
              background: #FFF;
              color: #1E2340;
              font-size: 1.5rem;
              line-height: 2.4rem;
              letter-spacing: -0.016rem;
              &:hover{
                  background: #EFEFEF;
                  color: #292929;
              }
          }
      }
  }
}

.sidebar-alunos-recorrentes{
  h3{
      color: #1E2340;
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 10px;
  }
  .alunos-recorrentes{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap:1.0rem;
      align-items: flex-start;
      @include breakpoint($celular){
          justify-content: flex-start;
          gap: 3rem;
          padding-left: 2rem;
      }
  }
}

.alunos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  transition: 0.3s;
  max-width: 86px;
  width: 100%;
  &:hover{
    opacity: 0.9;
    .img-aluno{
        filter: grayscale(0);
    }
    .iniciais-perfil{
        filter: grayscale(0);
    }
  }
  .iniciais-perfil{
      border: 2px solid #FFFFFF;
      border-radius: 50%;
      padding: 3px;
      height: 47px;
      max-width: 47px;
      width: 100%;
      span{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:var(--color-gray-200);
        color: var(--color-white);
        font-weight: bold;
        border-radius: 50%;
      }
      &.alerta{
          border-color: #F25C05;
      }
      &.atencao{
          border-color:#EBFF00;
      }
      img{
          border-radius: 51.934px;
      }
  }
  .img-aluno{
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    padding: 3px;
    height: 47px;
    max-width: 47px;
    width: 100%;
    overflow: hidden;
    span{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:var(--color-gray-200);
      color: var(--color-white);
      font-weight: bold;
      border-radius: 50%;
    }
    &.alerta{
        border-color: #F25C05;
    }
    &.atencao{
        border-color:#EBFF00;
    }
    img{
        border-radius: 51.934px;
    }
}
  .img-aluno-oval{
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    padding: 3px;
    height: 47px;
    max-width: 37px;
    width: 100%;
    span{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:var(--color-gray-200);
      color: var(--color-white);
      font-weight: bold;
      border-radius: 50%;
    }
    &.alerta{
        border-color: #F25C05;
    }
    &.atencao{
        border-color:#EBFF00;
    }
    img{
        border-radius: 51.934px;
    }
}
  .nome-aluno{
      color: #6B6B6B;
      font-size: 1.0722rem;
      font-weight: 600;
      text-align: center;
  }
}
