


.tabulator-col.tabulator-sortable.tabulator-col-sorter-element{
  background-color: #0F7BA6 !important;
  color: #fff;
}

.tabulator {
  & .tabulator-tableholder{
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--color-white-500);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #211916;
      border-radius: 20px;
      border: 3px solid #211916;
    }
  }
  & .tabulator-col-resize-handle{
    // width: 3px!important;
    background-color: #FFF;
  }
  & .tabulator-header{
    border-bottom: 2px solid #FFF!important;
    border-top: 1px solid #FFF!important;
    & .tabulator-col {
      & .tabulator-col-content {
        // border-right: 1px solid #FFF;
        & .tabulator-col-title{
          font-size: 1.2rem; /* 2 rem para quebra e criar barra de rolagem */
          font-weight: 700;
          i{
            color:#808080;
            &:hover{
                  color:#107BA7;
            }
          }
        }
      }
    }
  }
  & .tabulator-footer{
    & .tabulator-footer-contents {
      background-color: #FFF;
    }
  }
}


.tabulator-row{
  & .tabulator-cell{
    color: #595959;
    font-size: 1.2rem;
  }
  &.tabulator-selectable{
    &.tabulator-row-odd{
      background: var(----color-gray-100, #E1E9EF);
      border-bottom: 1.5px solid #FFF;
    }
    &.tabulator-row-even{
      background: var(----color-white-600, #F6F6F6);
      border-bottom: 1.5px solid #FFF;
    }
    &.tabulator-selected{
      background: #0F7BA6;
      &.tabulator-cell{
        color: #FFF !important;
      }
    }
  }
}


// .tabulator{
//   i{
//     color: #808080;
//   }
// }

.table-buttons{
  justify-content:space-between!important;
  @include breakpoint($tablet){
    flex-direction: column;
    align-items: center;
  }
  & .filtros{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    gap: 8px;
    @include breakpoint($celular){
      flex-direction: column;
      width: 100%;
    }
    select, input{
      color: #333;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #ccc;
      min-height: 44px;
      align-items: center;
      padding: 0 5px;
      @include breakpoint($celular){
        width: 100%;
      }
    }
    button{
      background-color: #0f7ba6;
      color: #FFF;
      font-weight: 500;
      padding: 0.8em;
      border-radius: 4px;
      @include breakpoint($celular){
        width: 100%;
      }
      &:hover{
        opacity: 0.8;
      }
    }
  }
  & .buttons{
    display: flex;
    gap: 8px;
    @include breakpoint($celular){
      width: 100%;
    }
    .excel, .pdf, .impressao {
      @include breakpoint($celular){
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
