@import "../../../node_modules/breakpoint-sass/stylesheets/breakpoint";

@import './variables';

.section-filtro{
    padding: 20px 0;
    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        @include breakpoint($celular){
            flex-direction: column;
            align-items: flex-start;
        }
        .select2-container--default{

            .select2-selection--single{
                height: 32px !important;
                line-height: 34px!important;
                .select2-selection__rendered {
                    line-height: 28px;
                    font-weight: 400;
                    font-size: 12px;
                }
                .select2-selection__placeholder {
                    font-weight: 400;
                    font-size: 12px;
                }
                .select2-selection__clear {
                    color: var(--color-white)!important;
                }
            }

            .select2-selection--multiple {
                display: inline-flex;
                @include breakpoint($celular){
                    width: 100%!important;
                    max-width: 480px;
                }
                .select2-selection__rendered {
                    display: flex;
                    flex-direction: row;
                    // width: 284px
                }
                textarea{
                    &:-moz-placeholder{ /* Modern browsers */
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 25px;
                        padding-left: 5px;
                    }
                    &::-moz-placeholder{ /* Modern browsers */
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 25px;
                        padding-left: 5px;
                    }
                    &:-ms-input-placeholder{ /* Modern browsers */
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 25px;
                        padding-left: 5px;
                    }
                    &::-webkit-input-placeholder{ /* Modern browsers */
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 25px;
                        padding-left: 5px;
                    }
                    &::placeholder { /* Modern browsers */
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 25px;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}
.grupo-filter{
    display: flex;
    flex-direction: column;
    // width: 100%;
    // height: 109px;
    @include breakpoint($celular){
        width: 100%!important;
        max-width: 480px;
    }
    p{
      b{
          color: #6B6B6B;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      }
  }
  label{
    color: #6B6B6B;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  select{
    border-radius: 4px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-white);
    display: flex;
    height: 4rem;
    padding: 0.4rem;
    align-items: center;
    gap: 0.8rem;
    align-self: stretch;
  }
}
.select2-container{
    @include breakpoint($celular){
        width: 100%!important;
        max-width: 480px;
    }
}
.filtro-time-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 5px 10px;
    gap: 20px;
    label{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: var(--color-blue);
    }
    select{
        height: 4rem;
        border-radius: 4px;
        border: 1px solid #CCC;
        background: var(--color-white);
        padding-left: 1.6rem;
        font-size: 1.4rem;
    }
    .select2-container{
        width: 190px!important;
    }
    .select2-container--default{
        .select2-selection--single {
            height: 35px;
            .select2-selection__rendered {
                line-height: 33px;
            }
            .select2-selection__arrow {
                height: 33px;
            }
        }
    }
}
