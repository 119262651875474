.section-saudacao{
  .container{
    .titulo-page{
        color: var( --color-black-100);
        font-size: 3rem;
        font-weight: 600;
        margin: 23px 0 53px 0;
    }
      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-black-100);
      }
  }
}

.conteudo-principal{
  .container-conviva{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      @include breakpoint($tablet){
         gap:2rem;
         padding: 1rem ;
      }
      @include breakpoint($celular){
          gap:1rem;
          flex-direction: column;
      }
      .time-line{
          display: flex;
          flex-direction: column;
          padding: 15px 15px;
          gap: 5px;
          background-color: var(--color-white-600);
          border-radius: 3.2rem 3.2rem 0rem 0rem;
          max-width: 592px;
          width: 100%;
          h2{
              font-style: normal;
              font-weight: 600;
              font-size: 2.0rem;
              line-height: 2.8rem;
              color: var(--color-black);
              margin: 1rem 0;
          }
      }

      .sidebar{
          position: sticky;
          top: 5%;
          max-width: 384px;
          width: 100%;
          height: 445px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.2rem;
          @include breakpoint($tablet){
              max-width: 250px;
          }
          @include breakpoint($celular){
              max-width: 100%;
              height: auto;
          }
      }
  }
}

