// INDICE FORMULARIO
.indice-form{
  padding:0 0 50px 0;
  .container{
    display: flex;
    width: 65%;
    height: 0.4375rem;
    padding:3px 3.125rem;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.375rem;
    border: 1px solid #CBCBCB;
    background-color: #D9D9D9;
    @include breakpoint($tablet){
      width: 90%;
    }
    .icon-indice{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29px;
      height: 29px;
      padding: 0;
      border-radius: 50%;
      border: 1px solid #D9D9D9;
      background-color: #F6F6F6;
      span{
        display: block;
        width: 19px;
        height: 19px;
        background-color: #D9D9D9;
        border-radius: 50%;
      }
      &.ativo{
        border: 1px solid #0F7BA6;
        span{
          background-color: #0F7BA6;
        }
      }
      &:hover{
        border: 1px solid #0F7BA6;
        span{
          background-color: #0F7BA6;
        }
      }
    }
  }
}

.conteudo-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include breakpoint($celular){
    flex-direction: column;
  }
  .box-duas-colum{
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 100%;
    gap: 0.5625rem;
    // &:nth-child(2){
    //   align-items: flex-end;
    // }
    @include breakpoint($tablet){
      .grupo-filter{
        max-width: 312px;
        width: 100%;
      }
    }
  }
}
.deslocamento{
  display: flex;
  flex-direction: row;
  padding: 0rem 1.875rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @include breakpoint($celular){
    flex-direction: column;
  }
  & h3{
    color: var(----color-black-100, #1E2340);
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .box-duas-colum{
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 100%;
    gap: 0.5625rem;
    // &:nth-child(2){
    //   align-items: flex-end;
    // }
    @include breakpoint($tablet){
      .grupo-filter{
        max-width: 312px;
        width: 100%;
      }
    }
  }
}

.form-vista-psicologo{
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  gap: 10px;
  background-color: var(--color-white-600);
  border-radius: 3.2rem;
  width: 100%;
}

.conteudo-tempo-deslocamento{
  display: flex;
  justify-content: flex-end;
  .grupo-filter{
    @include breakpoint($tablet){
      max-width: 88%!important;
    }
    input{
      justify-content: center;
      background: #EEE!important;
    }
  }
  @include breakpoint($tablet){
    justify-content: center;
  }
}


.container-btns-visitas{
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  input {
    width: 25%;
    cursor: pointer;
    @include breakpoint($tablet){
      width: 100%;
    }
  }
}


.grupo-filter-full{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 100%;
  width: 100%;
  .icon-arquivo{
    display: block;
    width: auto;
    height: 20px;
    background-image: url(../../assets/img/icons/icon-input-upload.svg);
    padding-left: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    color: var(----color-gray-700, #6B6B6B);
    text-decoration: underline;
    cursor: pointer;
  }
  p{
    color: var(----color-gray-700, #6B6B6B);
    word-wrap: break-word;
  }
  label{
    color: var(----color-gray-700, #6B6B6B);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    input{
      &.custom-check{
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          border: 1px solid var(--color-gray-200);
          background: var(--color-white);
          box-shadow: none;
       }
       &:checked::after{
          content: " ";
          color: #ffffff;
          background-color: #0075FF;
          font-size: 16px;
          position: absolute;
          top: 1px;
          left: 1px;
          width: 100%;
          border-radius: 4px;
          text-align: center;
          background-size: cover;
          height: 100%;
          background-image: url(../../assets/img/icons/icon-check.svg)!important;
          background-position: center center;
        }
      }
      &[type="checkbox"]{
        width: 20px;
        height: 20px;
        margin-left: 20px;
      }
      &[type="radio"]{
        width: 20px;
        height: 20px;
        margin-left: 20px;
      }
    }
  }
  input{
    height: 4rem;
    &[type="file"]{
      width: 100%!important;
      max-width: 101.8rem !important;
      background-image: url(../../assets/img/icons/icon-input-upload.svg)!important;
    }
  }
  input, textarea{
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    &.input-date{
      justify-content: space-between;
      font-family: "Montserrat", sans-serif;
    }
    border-radius: 4px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-white);
    display: flex;
    padding: 0.4rem;
    align-items: center;
    gap: 0.8rem;
    align-self: stretch;
  }
}


//
.conteudo-range-slider{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
  label{
    color: var(--color-black);
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  input[type="range"]{
    // border-radius: 0.375rem;
    // border: 1px solid #CBCBCB;
    // background-color: #FEFEFE;
    // position: relative;
    // &::after{
    //   content: '0mn';
    //   position: absolute;
    //   left: -3px;
    //   top: 20px;
    //   color: #c0c0c0;
    //   font-size: 14px;
    //   font-weight: 500;
    // }
    // &::before{
    //   content: "90mn";
    //   position: absolute;
    //   right: -3px;
    //   top: 20px;
    //   color: #c0c0c0;
    //   font-size: 14px;
    //   font-weight: 500;
    // }
  }

}

.conteudo-envolvidos{
  padding-bottom: 20px;
  h3{
    color: var(----color-black-200, var(----color-black-200, #221A17));
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  .envolvidos-aluno{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6px;
    @include breakpoint($tablet){
      grid-template-columns: 1fr 1fr;
    }
    @include breakpoint($celular){
      grid-template-columns: 1fr ;
    }
  }
}

.perfil-aluno-chamado{
  border-radius: 1rem;
  background: var(----color-blue-100, #E8EFF1);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 12px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  @include breakpoint($tablet){

  }
  input{
    &:checked {
      background-color: #0F7BA6;
    }
  }
  .check-envolvidos{
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;

  }
  .img-aluno{
    background-color: var(----color-gray-200, #C1C9D8);
    // border: 2px solid #FFFFFF;
    border-radius: 50%;
    max-width: 69px;
    height: 69px;
    width: 69px;
    position: relative;
    span{
      height: 69px;
      width: 69px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-gray-200);
      color: var(--color-white);
      font-weight: bold;
      border-radius: 50%;
    }
  }
  .infor-aluno-chamado{
    .nome-aluno{
      color: #000;
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: -0.024rem;
    }
    .descricao{
      color: #000;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}


/* input type checkbox */
input[type="checkbox"].checkbox {
  display: none;
}
label input[type="checkbox"].checkbox + i {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  width: 32px;
  height: 18px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(160, 160, 160, 0.9);
}
label input[type="checkbox"].checkbox + i,
label input[type="checkbox"].checkbox + i *,
label input[type="checkbox"].checkbox + i:before,
label input[type="checkbox"].checkbox + i:after {
  -webkit-transition: All 180ms ease;
  -moz-transition: All 180ms ease;
  transition: All 180ms ease;
}
label input[type="checkbox"].checkbox + i:before,
label input[type="checkbox"].checkbox + i:after {
  content: "\00a0";
  position: absolute;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
}
 label input[type="checkbox"].checkbox + i:hover:before,
label input[type="checkbox"].checkbox + i:hover:after {
  visibility: visible;
  opacity: 1;
}
label input[type="checkbox"].checkbox + i:before {
  top: 5px;
  left: 0px;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  transform: rotate(225deg);
}
label input[type="checkbox"].checkbox + i:after {
  visibility: visible;
  opacity: 1;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: #a0a0a0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
}
label input[type="checkbox"].checkbox + i:hover:before {
  // left: calc(100% - 12px);
  left: 12px;
  border-color: rgba(0, 0, 0, 0.7);
}
label input[type="checkbox"].checkbox + i:hover:after {
  top: -1px;
  left: -2px;
  width: 18px;
  height: 18px;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
label input[type="checkbox"]:not(:checked).checkbox + i {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  width: 32px;
  height: 18px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(160, 160, 160, 0.9);
}
label input[type="checkbox"]:not(:checked).checkbox + i,
label input[type="checkbox"]:not(:checked).checkbox + i *,
label input[type="checkbox"]:not(:checked).checkbox + i:before,
label input[type="checkbox"]:not(:checked).checkbox + i:after {
  -webkit-transition: All 180ms ease;
  -moz-transition: All 180ms ease;
  transition: All 180ms ease;
}
label input[type="checkbox"]:not(:checked).checkbox + i:before,
label input[type="checkbox"]:not(:checked).checkbox + i:after {
  content: "\00a0";
  position: absolute;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
}
label input[type="checkbox"]:not(:checked).checkbox + i:hover:before,
label input[type="checkbox"]:not(:checked).checkbox + i:hover:after {
  visibility: visible;
  opacity: 1;
}
label input[type="checkbox"]:not(:checked).checkbox + i:before {
  top: 5px;
  left: 0px;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  transform: rotate(225deg);
}
label input[type="checkbox"]:not(:checked).checkbox + i:after {
  visibility: visible;
  opacity: 1;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: #a0a0a0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
}
label input[type="checkbox"]:not(:checked).checkbox + i:hover:before {
  // left: calc(100% - 12px);
  left: 12px;
  border-color: rgba(0, 0, 0, 0.7);
}
label input[type="checkbox"]:not(:checked).checkbox + i:hover:after {
  top: -1px;
  left: -2px;
  width: 18px;
  height: 18px;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
label input[type="checkbox"]:checked.checkbox + i {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  background-color: #8BC34A;
  border: 1px solid rgba(139, 195, 74, 0);
}
label input[type="checkbox"]:checked.checkbox + i,
label input[type="checkbox"]:checked.checkbox + i *,
label input[type="checkbox"]:checked.checkbox + i:before,
label input[type="checkbox"]:checked.checkbox + i:after {
  -webkit-transition: All 180ms ease;
  -moz-transition: All 180ms ease;
  transition: All 180ms ease;
}
label input[type="checkbox"]:checked.checkbox + i:before,
label input[type="checkbox"]:checked.checkbox + i:after {
  content: "\00a0";
  position: absolute;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
}
label input[type="checkbox"]:checked.checkbox + i:hover:before,
label input[type="checkbox"]:checked.checkbox + i:hover:after {
  visibility: visible;
  opacity: 1;
}
label input[type="checkbox"]:checked.checkbox + i:before {
  border-color: #aaaaaa;
  left:8px;
  // left: calc(100%  -  8px);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
label input[type="checkbox"]:checked.checkbox + i:after {
  visibility: visible;
  opacity: 1;
  // left: calc(100%  -  14px);
  left: 14px;
  background-color: #fff;
}
label input[type="checkbox"]:checked.checkbox + i:hover:before {
  border-color: rgba(255, 255, 255, 0.7);
  left: 5px;
}
/* end input type checkbox */


.btn-close-convidados{
  background-color: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-image: url(../../assets/img/icons/close-convidados.svg);
  background-repeat: no-repeat;
  background-position: center center;
  &:hover{
    background-color: #BD362F;
  }
}



.grid-datatable {
  justify-items: center;
  max-width: 1246px;
  width: 100%;
  // border: 1px solid #ddd;
  // padding: 10px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  @include breakpoint($celular){
    overflow: scroll;
  }

  // border-radius: 11.644px;
  .dataTables_wrapper{
      max-width: 1246px;
      width: 100%;
      .dataTables_filter{
          input{
              display: flex;
              width: 22rem;
              padding: 0rem 2.1rem;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
              border-radius: 3rem!important;
              border: 1px solid var(----color-blue, #0F7BA6)!important;
          }
      }
  }
  table{
      max-width: 1246px;
      width: 100%;
      thead{
          color:  #FFF;
          tr{
              th{
              text-align: center;
              font-variant-numeric: lining-nums proportional-nums;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              background:  #0F7BA6;
              border: 3px solid transparent;
              padding: 10px 0;
              }
          }
      }
      tbody{
          tr{
              &:nth-child(odd){
                  background: var(----color-gray-100, #E1E9EF);
              }
              &:nth-child(even){
              background: var(----color-white-600, #F6F6F6);
              }
              td{
                padding: 8px 0;
                  border: 3px solid  transparent;
                  color: #595959;
                  text-align: center;
                  font-variant-numeric: lining-nums proportional-nums;
                  font-size: 1.2rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  a{
                      i{
                          color:#808080;
                      }
                      &:hover{
                          i{
                              color:#107BA7;
                          }
                      }
                  }
              }
          }
      }
  }
}

.form-aprovacao-diretor{
  .grupo-filter-full{
    label{
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      input[checkbox]{
        height: 17px;
        width: 17px;
      }
    }
  }
}


.container-btns-encerrar-chamado{
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 0.5rem 0rem;
  input[type="radio"].encerrar-sim {
    -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  width: 100px; /* Aumentado */
  height: 45px; /* Aumentado */
  border: 0;
  border-radius: 25px; /* Ajustado */
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    border: none;
    content: "CONCLUIR";
    position: absolute;
    background-color: #0F7BA6;
    color: #ffffff;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100px; /* Aumentado */
    height: 45px; /* Aumentado */
    border-radius: 25px; /* Ajustado */
  }

  &:checked:before {
    content: "CONCLUIR";
    color: var(--color-white);
    background-color: #0F7BA6;
  }
  }
  input[type="radio"].encerrar-nao {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    width: 79px;
    height: 36px;
    border: 0;
    border-radius: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
      &:hover{
      }
      &:before {
        border:2px solid #EAEAEA;
        content: "NÃO";
        position: absolute;
        background-color: #FFFFFF;
        color: #a8a8a8;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 79px;
        height: 36px;
        border-radius: 20px;
      }
      &:checked {
          /*
          background: -o-linear-gradient(315deg, #8175fe, #89befe);
        background: linear-gradient(135deg, #8175fe, #89befe);
          */
      }
      &:checked:before {
        border:2px solid #0F7BA6;
        content: "NÃO";
        color: #0F7BA6;
        background-color: #FFF;
      }
  }
}


.btn-encerrar-chamado{
  display: flex;
  align-items: flex-start;
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;
  width: 79px;
  height: 36px;


}

.modal-conteudo-busca-aluno{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.header-modal-filtro{
  position: relative;
  margin-top: 20px;
  padding: 0 0 10px 0;
  .titulo-page{
    // text-align: center;
    // font-weight: 600;
    // font-size: 2rem;
    // line-height: 2.8rem;
    // color: var(--color-black);
  }
  .btn-fechar-modal{
    width: 30px;
    height: 30px;
    display: block;
    background-image: url(../../assets/img/icons/icon-close.svg);
    text-indent: -999px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: -24px;
    right: -18px;
    cursor: pointer;
    overflow: hidden;
    &:hover{
      opacity: 0.8;
    }
  }
}
