:root {
  //WHITE
  --color-white:#FFFFFF;
  --color-white-900:#EFEFEF;
  --color-white-800:#F2F2F2;
  --color-white-700:#EBEBEB;
  --color-white-500:#F0F0F0;
  --color-white-600:#F6F6F6;

  //BLACK
  --color-black:#000000;
  --color-black-900:#050505;
  --color-black-800:#181818;
  --color-black-200:#221a17;
  --color-black-100:#1E2340;

  //YELLOW
  --color-yellow:#FF9B04;
  --color-yellow-100:#FDC97C;

  //GREEB
  --color-green:#54D969;
  --color-green-100:#E8F3EC;
  --color-green-500:#21A336;

  //BLUE
  --color-blue:#0F7BA6;
  --color-blue-900:#0000FF;
  --color-blue-800:#04B4FF;
  --color-blue-700:#0A66C2;
  --color-blue-600:#3D77B7;
  --color-blue-100:#E8EFF1;
  --color-blue-grid:#459ad6;

  //GRAY
  --color-gray-800:#333;
  --color-gray-700:#6B6B6B;
  --color-gray-600:#595959;
  --color-gray-500:#898A8D;
  --color-gray-300:#B3BAC5;
  --color-gray-200:#C1C9D8;
  --color-gray-100:#e1e9ef;

  //RED
  --color-red-600:#E91D62;
  --color-red-100:#F6EBEB;
  --color-red:#F00;

  --color-verde-presenca: #20C7A3;
  --color-vermelho-falta: #E53239;
  --color-cinza-desabilitado: #BDBDBD;


}

$iphone5: max-width 320px;
$celular: max-width 479px;
$paisagem: max-width 768px;
$tablet: max-width 991px;
$laptop: max-width 1366px;
$desktop: max-width 1150px;

@keyframes bellshake {
  0% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(4deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(2deg); }
  85% { transform: rotate(-2deg); }
  92% { transform: rotate(1deg); }
  100% { transform: rotate(0); }
}
