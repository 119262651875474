
.login_plataforma_conviva{
  background-image: url(../../assets/img/bg-login.jpg);
  background-position: center center;
  background-size: cover;
  padding: 20px 0;
  .container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      @include breakpoint($celular){
        height: auto;
      }
  }
  .form-login{
      max-width: 384px;
      width: 100%;
      background-color: var(--color-white);
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      gap: 20px;
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: center;

        color: var(--color-black);
      }
      form{
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
          width: 100%;
          padding: 15px;
          label{
            font-style: normal;
            font-weight: 400;
            font-size: 1.3rem;
            line-height: 1.7rem;
            color: var(--color-gray-600);
          }
          input{
            background-color: var(--color-white);
            border: 1px solid var(--color-gray-200);
            border-radius: 3.12409px;
            height: 46.16px;
            padding: 15px 15px;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: var(--color-black);
            &::-webkit-input-placeholder { /* Edge */
              font-size: 1.2rem;
              line-height: 1.7rem;
              color: var(--color-gray-200);
              }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              font-size: 1.2rem;
              line-height: 1.7rem;
              color: var(--color-gray-200);
              }

            &::placeholder{
              font-size: 12.4964px;
              line-height: 17px;
              color: var(--color-gray-200);
            }
          }
          a{
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.7rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: var(--color-blue-600);
            &:hover{
              text-decoration: underline;
            }
          }
      }
    .logo-gov-sp{

    }
  }
}
