@import "../../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import './variables';

header{
  background-color: var(--color-white);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.04);
  .container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8rem;
    @include breakpoint($tablet){
      padding: 10px 15px;
    }
    @include breakpoint($celular){
      padding: 10px 15px;
    }
    .item-menu{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 10px;
      @include breakpoint($celular){
        gap: 6px;
      }
      .icon-nav{
        display: block;
        cursor: pointer;
        width: 5.15rem;
        height: 4.1rem;
        background-image: url(../../assets/img/icons/icon-btnNav.svg);
        background-position: center center;
        &:hover{
          opacity: 0.8;
        }
      }
      .logo-brasao{
        display: block;
        width: 45.99px;
        height: 45.99px;
        &:hover{
          opacity: 0.9;
        }
      }
    }
    .conteudo-search{
      background-color: rgba(245, 245, 245, 0.85);
      border-radius: 122px;
      width: 100%;
      max-width: 47.8rem;
      height: 5.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoint($tablet){
        max-width: 25.8rem;
      }
      @include breakpoint($celular){
        display: none;
      }
      input[type=text]{
        background-color: rgba(245, 245, 245, 0.85);
        border-radius: 32px;
        width: 80%;
        height: 100%;
        border: 0;
        outline: none;
        font-size: 1.5rem;
        line-height: 150%;
        color: var(--color-black-800);
        padding-left: 1.5rem;
        padding-right: 0.7rem;
      }
      button {
        width: 42px;
        height: 42px;
        background-color: var(--color-gray-100);
        border-radius: 50%;
        border: 1px solid transparent;
        transition: border-color .3s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        text-indent: -9999px;
        &:hover {
          border-color: var(--color-black-800);
        }
      }
    }
    .conteudo-perfil{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 12px;
      @include breakpoint($celular){
        gap: 6px;
      }
      .icon-add-chamado{
        width: 39px;
        height: 39px;
        display: none;
        @include breakpoint($celular){
          display: block;
        }
        .add-chamado{
          background-image: url(../../assets/img/icons/icon-chamado.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 39px;
          height: 39px;
          display: block;
          position: relative;
          text-indent: -9999px;
          &:hover{
            opacity: 0.8;
          }
        }
      }

      .icon-notificacao{
        width: 39px;
        height: 39px;
        display: block;
        .notificacao{
          background-image: url(../../assets/img/icons/icon-bell.svg);
          width: 39px;
          height: 39px;
          display: block;
          position: relative;
          &:hover{
            opacity: 0.8;
            animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
            backface-visibility: hidden;
            transform-origin: top right;
          }
          p{
            position: absolute;
            width: 17px;
            height: 17px;
            left: 23px;
            top: 7px;
            background-color: var(--color-red-600);
            border: 1px solid var(--color-white);
            border-radius: 53px;
            color: var(--color-white);
            font-style: normal;
            font-weight: 700;
            font-size: 8px;
            padding-left: 1px;
            text-align: center;
            line-height: 15px;
          }
        }
      }
      .perfil{
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        overflow: hidden;
        border:solid 2px var(--color-white-900);
        padding: 2px;
        &:hover{
          opacity: 0.9;
        }
        span{
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color:var(--color-gray-200);
          color: var(--color-white);
          font-weight: bold;
          border-radius: 50%;
        }
        img{
          width: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}

.btn-logout{
  background-image: url(../../assets/img/icons/icon-logout-two.png);
  display: block;
  text-indent: -9999px;
  width: 40px;
  height: 40px;
  background-position: center top;
  background-repeat: no-repeat;
  // transition: 0.3s ease;
  &:hover{
    background-position: center bottom;
  }
}

.sidebar-menu{
  width: 100%;
  max-width: 320px;
  height: 100%;
  position:fixed;
  left:-100%;
  top: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.938);
  transition: all 0.3s ease;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 70px 15px 0px;
  &.active{
    top: 0;
    left: 0;
  }
  nav{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 100%;
    .perfil-menu{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .perfil{
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        overflow: hidden;
        border:solid 2px var(--color-white-900);
        padding: 2px;
        &:hover{
          opacity: 0.9;
        }
        span{
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color:var(--color-gray-200);
          color: var(--color-white);
          font-weight: bold;
          border-radius: 50%;
        }
        img{
          width: 100%;
          border-radius: 50%;
        }
      }
      .sidebar-menu-fechar{
        width: 24px;
        height: 24px;
        display: block;
        cursor: pointer;
        background-image: url(../../assets/img/icons/btn-close-nav.svg);
        background-repeat: no-repeat;
        background-position: center center;
        &:hover{
          opacity: 0.8;
        }
      }
    }
    .identidade-menu{
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-black-100);
        b{
          font-weight: 600;
        }
      }
    }
    ul{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100vh - 217px);
      &::-webkit-scrollbar-track {
        background-color: var(--light-mode-barra-scroll,rgba(255, 255, 255, 0.938));
      }
      &::-webkit-scrollbar {
        width: 6px;
        background: var(--light-mode-barra-scroll,rgba(255, 255, 255, 0.938));
      }
      &::-webkit-scrollbar-thumb {
        background: var(--light-mode-scroll, #CDCDCD);
        border-radius: 4px;
        border: 3px solid var(--light-mode-scroll, #CDCDCD);
      }
      li{
        width: 100%;
        a{
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          padding: 8px;
          color: var(--color-black-100);
          background-color: var(--color-white-800);
          border-radius: 8px;
          transition: all 0.3s ease;
          &:hover{
            background-color: var(--color-white);
            box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.10);
          }
          &.active{
            background-color: var(--color-white);
            box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.10);
          }
        }
      }
    }
  }
}


.nav-dropdown{
  display: none;
    &~ label{
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.01em;
      padding: 8px;
      color: var(--color-black-100);
      background-color: var(--color-white);
      border-radius: 8px;
      transition: all 0.3s ease;
      cursor: pointer;
      position: relative;
      opacity: 0.85;
      &::before{
        position: absolute;
        content: "";
        top: 14px;
        right: 11px;
        width: 16px;
        height: 16px;
        background-image: url('../../assets/img/icons/icon-seta-baixo.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(0deg);
        transition: transform 0.5s;
      }
      &:hover{
        background-color: var(--color-white);
        box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.10);
      }
  }
  &~.opcoes-dropdown-itens{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    a{
      background-color: #e2e2e2;
      margin-left: 8px;
    }
  }
  &:checked{
      &~.opcoes-dropdown-itens{
          display: none;
      }
      &~ label{
          background-color: var(--color-white-800);
          &::before{
            top: 12px;
            transform: rotate(-90deg);
          }
          .btn-opcoes-card{
              opacity: 0.6;
          }
      }
    }
}
