.section-stores-destaque{
  padding: 20px 0;
  overflow: hidden;
  .container-conviva{

      display: flex;
      flex-direction: row;
      align-items: center;
      @include breakpoint($celular){
      }
      gap: 20px;
      .swiper {
          width: 100%;
          height: 100%;
      }
  }
}
