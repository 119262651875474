
.card-stores{
  object-fit: cover;
  border-radius: 10px;
  background-position: top center;
  background-size: cover;
  width: 17.6rem!important;
  height: 25.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-right:0px!important;
  transition: all 0.3s ease;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0966) 59.52%, rgba(0, 0, 0, 0.69) 75%);
  }
  &:hover{
      &::before{
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.0966) 35%, rgba(0, 0, 0, 0.69) 45%);
      }
      .card-aluno{
          .descricao-aluno{
            display: block;
          }
      }
  }
  .opcoes-card{
    position: absolute;
    top: 56px;
    right: 5px;
    display: inline-flex;
    padding: 5px 10px;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    background-color: var(--color-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 90;
    a{
        color: var(--color-black-900);
        font-size: 1.5rem;
    }
  }

  .btn-option{
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;
    width: 23px;
    height: 23px;
    display: block;
    background-image: url(../../assets/img/icons/icon-option.svg);
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 90;
    opacity: 1;
    cursor: pointer;
  }
  .card-opcoes-stores{
    display: none;
    &:checked{
        &~.opcoes-card{
            display: none;
        }
        &~ label{
            .btn-option{
                opacity: 0.5;
            }
        }
    }
  }
  .card-aluno{
    padding: 0 5px;
    margin-bottom: 8px;
    z-index: 90;
    .foto-aluno{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 0.8rem;
        span{
            width: 47px;
            height: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color:var(--color-gray-200);
            color: var(--color-white);
            font-weight: bold;
            border-radius: 50%;
          }
        img{
            width: 47px;
            height: 47px;
            border-radius: 50%;
        }
        .info-aluno{
            p{
                font-weight: 600;
                font-size: 1.3rem;
                color: var(--color-white);
                small{
                    font-weight:400;
                }
            }
        }
    }
      .descricao-aluno{
          display: none;
          transition: all 0.3s ease;
          p{
              font-weight: 400;
              font-size: 1.2rem;
              color: var(--color-white);
          }
      }
  }
}


.card-timeline{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background-color: var(--color-white);
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 17px;
    &.ocultar-card{
        .header-card{
            .btn-view-card{
                will-change: transform;
                transform: rotate(180deg);
            }
        }
        .imagem-destaque-card{
            display: none;
        }
        .descricao-card{
            display: none;
        }
        .footer-card{
            display: none;
        }
    }
    .header-card{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding: 0px;
        width: 100%;
        .perfil-aluno{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 12px;
            span{
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color:var(--color-gray-200);
                color: var(--color-white);
                font-weight: bold;
                border-radius: 50%;
            }
            .img-aluno{
                border-radius: 50%;
                width: 4.8rem;
                height: 4.8rem;
                overflow: hidden;
                @include breakpoint($tablet){
                  width: 8.8rem;
                  height: 4.8rem;
                }
                img{
                    width: 100%;
                }
            }
            .iniciais-perfil{
                border-radius: 50%;
                width: 4.8rem;
                height: 4.8rem;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            .img-aluno-oval{
                border-radius: 50%;
                width: 3.8rem;
                height: 4.8rem;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            .info-aluno{
                p{
                    font-weight: 600;
                    font-size: 1.6rem;
                    line-height: 15px;
                    letter-spacing: -0.24px;
                    color: var(--color-black);
                    small{
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        letter-spacing: -0.24px;
                        color: #898A8D;
                    }
                }
            }
        }
        .btn-acoes{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 32px;
            position: relative;
            .btn-view-card{
                transition: transform .4s ease-in-out;
                cursor: pointer;
                width: 12px;
                height: 22px;
                display: block;
                background-image: url(../../assets/img/icons/icon-swipeup.svg);
            }
            .btn-opcoes-card{
                cursor: pointer;
                width: 5px;
                height: 23px;
                display: block;
                background-image: url(../../assets/img/icons/icon-option-black.svg);
                background-position: center center;
                opacity: 1;
            }
            .card-opcoes-card{
                display: none;
                &:checked{
                    &~.opcoes-card{
                        display: none;
                    }
                    &~ label{
                        .btn-opcoes-card{
                            opacity: 0.6;
                        }
                    }
                }
            }
            .opcoes-card{
                position: absolute;
                top: 56px;
                right: 0;
                display: inline-flex;
                padding: 5px 10px;
                flex-direction: column;
                align-items: flex-end;
                gap: 10px;
                background-color: var(--color-white);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                z-index: 90;
                a{
                    color: var(--color-black-900);
                    font-size: 1.5rem;
                }
            }
        }
    }
    .imagem-destaque-card{
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        width: 100%;
        img{
            width: 100%;
        }
    }
    .titulo-destaque-card{
        h3{
            a{
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 22px;
                letter-spacing: -0.24px;
                color: var(--color-black);
                &:hover{
                    color:lighten(#000000, 18%);
                }
            }
        }
    }
    .descricao-card{
        p{
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.5rem;
            color: var(--color-gray-600);
            a{
                font-size: 1.2rem;
                line-height: 1.5rem;
                color: #0000FF;
            }
        }
    }
    .footer-card{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 16px;
        width: 100%;
        .historico{
            background-image: url(../../assets/img/icons/icon-comment.svg);
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;
            a{
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 2.0rem;
                letter-spacing: -0.24px;
                color: var(--color-black);
                &:hover{
                    color: var(--color-gray-600);
                }
            }
        }
        .encaminhamento{
            background-image: url(../../assets/img/icons/icon-share.svg);
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;
            a{
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 2.0rem;
                letter-spacing: -0.24px;
                color: var(--color-black);
                &:hover{
                    color: var(--color-gray-600);
                }
            }
        }
    }
}



input.card-feed {
    display: none;
    z-index: 1;
    opacity: 0;
    &:checked{
        &~.header-card .btn-acoes{
                & label{
                    & .btn-view-card{
                        will-change: transform;
                        transform: rotate(180deg);
                    }
                }
        }
        &~.imagem-destaque-card{
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            display: none;
        }
        &~.descricao-card{
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            display: none;
        }
        &~.footer-card{
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            display: none;
        }
    }
}



.card-chamado{
    display: flex;
    padding: 1rem;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    border-radius: 13px;
    border: 1px solid var(--color-white-700);
    background-color: var(--color-white);
    &:hover{
        border: 1px solid var(--color-white);
        background-color: var(--color-white-500);
    }
    &.ativo{
       background-color: var(--color-white-600);
    }
    &.aberto{
      .photo-aluno{
        &::after{
          background-color: var(--color-gray-300);
        }
      }
      .card-chamado__info{
        .card-footer{
          .card-status{
              color: var(--color-gray-300);
          }
        }
      }
    }
    &.pendente{
      .photo-aluno{
        &::after{
          background-color: var(--color-yellow-100);
        }
      }
      .card-chamado__info{
        .card-footer{
          .card-status{
            color: var(--color-yellow-100);
          }
        }
      }
    }
    &.aguardando-agendamentoerto{
      .photo-aluno{
        &::after{
          background-color: var(--color-yellow);
        }
      }
      .card-chamado__info{
        .card-footer{
          .card-status{
              color: var(--color-yellow);
          }
        }
      }
    }
    &.agendado{
      .photo-aluno{
        &::after{
          background-color: var(--color-blue-800);
        }
      }
      .card-chamado__info{
        .card-footer{
          .card-status{
              color: var(--color-blue-800);
          }
        }
      }
    }
    &.atendido{
      .photo-aluno{
        &::after{
          background-color: var(--color-green);
        }
      }
      .card-chamado__info{
        .card-footer{
          .card-status{
              color: var(--color-green);
          }
        }
      }
    }
    &.encerrado{
      .photo-aluno{
        &::after{
          background-color: var(--color-green-500);
        }
      }
      .card-chamado__info{
        .card-footer{
          .card-status{
              color: var(--color-green-500);
          }
        }
      }
    }
    &.encaminhado{
        .photo-aluno{
            &::after{
                background-color: var(--color-red);
            }
        }
        .card-chamado__info{
            .card-footer{
                .card-status{
                    color: var(--color-red);
                }
            }
        }
    }
    &.resolvido{
        .photo-aluno{
            &::after{
                background-color: var(--color-green);
            }
        }
        .card-chamado__info{
            .card-footer{
                .card-status{
                    color: var(--color-green);
                }
            }
        }
    }

    &.nao-visualizado{
        .photo-aluno{
            &::after{
                background-color: var(--color-gray-300);
            }
        }
        .card-chamado__info{
            .card-footer{
                .card-status{
                    color: var(--color-gray-300);
                }
            }
        }
    }

    .photo-aluno{
        width: 6.9rem;
        height: 6.8rem;
        position: relative;
        &::after{
            position: absolute;
            bottom: -1px;
            left: 0;
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            border: 4px solid var(--color-white);
            background-color: var(--color-gray-100);
        }
        span{
            height: 90%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color:var(--color-gray-200);
            color: var(--color-white);
            font-weight: bold;
            border-radius: 50%;
        }
        img{
            width: 100%;
            border-radius: 69px;
        }
    }
    &__info{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.2rem;
        align-self: stretch;
        justify-content: space-between;
        .nome-aluno{
            color: var(--color-black);
            font-size: 1.6rem;
            font-weight: 600;
            letter-spacing: -0.024rem;
        }
        .descricao-chamado{
            color: var(--color-black);
            font-size: 1.2rem;
            line-height: 2rem;
        }

        .card-footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            p{
                small{
                    color: var(--color-gray-500);
                    text-align: center;
                    font-size: 1.1rem;
                    letter-spacing: -0.024rem;
                }
            }

            .card-status{
                font-size: 1.2rem;
                letter-spacing: -0.024rem;
                color: var(--color-gray-100);
            }
        }
    }
}


.card-notification{
    display: flex;
    padding: 1.2rem;
    align-items: flex-start;
    gap: 2.3rem;
    align-self: stretch;
    background-color:var(--color-white);
    border-bottom: 1px solid var(--color-blue);
    &.new-notification{
        background-color: #E2EFF4;
        position: relative;
        &::before{
            content:"";
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 5px;
            background-color: var(--color-blue-700);
            position: absolute;
            left: 2px;
            top: 30px;
        }
    }
    .foto-aluno{
        width: 3.9rem;
        height: 3.8rem;
        span{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color:var(--color-gray-200);
            color: var(--color-white);
            font-weight: bold;
            border-radius: 50%;
          }
        img{
            border-radius: 50%;
            width: 100%;
        }
    }
    .card-input-notification{
        display: none;
        &:checked{
            &~ .btn-acoes{
                & label{
                    & .btn-view-card{
                        will-change: transform;
                        transform: rotate(180deg);
                    }
                }
            }
            &~.info-card-notification{
                .card-descricao-old{
                    margin-top: 0;
                    max-height: 0;
                    opacity: 0;
                    display: none;
                }
                .card-btn-notification{
                    margin-top: 0;
                    max-height: 0;
                    opacity: 0;
                    display: none;
                }
            }
        }
    }
    .info-card-notification{
        .card-data{
            p{
                small{
                    color: var(--color-gray-700);
                    font-size: 1.2rem;
                }
            }
        }
        .card-titulo{
            p{
                color: var(--color-black);
                font-size: 1.6rem;
                font-weight: 600;
            }
        }
        .card-descricao{
            p{
                color: var(--color-gray-700);
                font-size: 1.2rem;
                line-height: 1.4rem;
            }
        }
        .card-descricao-old{
            border-top: solid 0.5px var(--color-gray-700);
            margin-top:10px;
            padding-top: 10px;
            .card-data{
                p{
                    small{

                    }
                }
            }
            .card-titulo{
                p{

                }
            }
            .card-descricao{
                p{
                    color: var(--color-gray-700);
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                }
            }
        }
        .card-btn-notification{
            color: var(--color-blue);
            leading-trim: both;
            text-edge: cap;
            font-size: 1.2rem;
            font-weight: 600;
            text-decoration-line: underline;
            background-image: url(../../assets/img/icons/btn-status-avito.svg);
            background-position: left center;
            background-repeat: no-repeat;
            background-size: contain;
            padding-left: 18px;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .btn-acoes{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 32px;
        position: relative;
        .btn-view-card{
            transition: transform .4s ease-in-out;
            cursor: pointer;
            width: 12px;
            height: 22px;
            display: block;
            background-image: url(../../assets/img/icons/icon-swipeup.svg);
        }
        .btn-opcoes-card{
            cursor: pointer;
            width: 5px;
            height: 23px;
            display: block;
            background-image: url(../../assets/img/icons/icon-option-black.svg);
            background-position: center center;
            opacity: 1;
        }
        .card-opcoes-card{
            display: none;
            &:checked{
                &~.opcoes-card{
                    display: none;
                }
                &~ label{
                    .btn-opcoes-card{
                        opacity: 0.6;
                    }
                }
            }
        }
        .opcoes-card{
            position: absolute;
            top: 40px;
            right: 0;
            display: inline-flex;
            padding: 5px 10px;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
            background-color: var(--color-white);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            z-index: 90;
            a{
                color: var(--color-black-900);
                font-size: 1.5rem;
            }
        }
    }
}



.card-casos{

    display: flex;
    flex-direction: row;

   .number-caso{
    width: 14rem;
    height: 9.9rem;
    flex-shrink: 0;
    border-radius: 17px;
    background: #c4c4c4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    padding-right: 3rem;
    font-size: 2.3288rem;
    font-weight: 600;
    line-height: 2.5616rem;
    @include breakpoint($tablet){
        width: 10rem;
    }
    &.alerta{
        background-color: #FF161F;
    }
    &.encaminhados{
        background-color: #0F7BA6;
    }
    &.encerrados{
        background-color: #0DC94D;
    }
   }
   .conteudo-card-caso{
        margin-left: -3rem;
        border-radius: 17px;
        box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.15);
        background-color:var(--color-white);
        display: flex;
        padding: 1.5rem 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;

        .header-caso{
            display: flex;
            justify-content: space-between;
            width: 100%;
            .titulo-card-caso{
                p{
                    color: #000;
                    font-size: 1.6rem;
                    font-weight: 600;
                    letter-spacing: -0.024rem;
                    small{
                        color: #898A8D;
                        text-align: center;
                        font-weight: 400;
                        font-size: 1rem;
                        letter-spacing: -0.024rem;
                    }
                }
            }

            .btn-acoes{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 32px;
                position: relative;
                .btn-view-card{
                    transition: transform .4s ease-in-out;
                    cursor: pointer;
                    width: 12px;
                    height: 22px;
                    display: block;
                    background-image: url(../../assets/img/icons/icon-swipeup.svg);
                }
                .btn-opcoes-card{
                    cursor: pointer;
                    width: 5px;
                    height: 23px;
                    display: block;
                    background-image: url(../../assets/img/icons/icon-option-black.svg);
                    background-position: center center;
                    opacity: 1;
                }

                .card-opcoes-card{
                    display: none;
                    &:checked{
                        &~.opcoes-card{
                            display: none;
                        }
                        &~ label{
                            .btn-opcoes-card{
                                opacity: 0.6;
                            }
                        }
                    }
                }
                .opcoes-card{
                    position: absolute;
                    top: 40px;
                    right: 0;
                    display: inline-flex;
                    padding: 5px 10px;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 10px;
                    background-color: var(--color-white);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    z-index: 90;
                    a{
                        color: var(--color-black-900);
                        font-size: 1.5rem;
                    }
                }
            }
        }
        .imagem-destaque-card{
            overflow: hidden;
            border-radius: 10px;
            position: relative;
            width: 100%;
            img{
                width: 100%;
            }
        }
        .info-card-caso{
            width: 100%;
            a{
                color: #000;
                font-size: 1.6rem;
                text-align: left;
                font-weight: 600;
                letter-spacing: -0.024rem;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
        .descricao-card-caso{
            p{
                color: #595959;
                font-size: 1.2rem;
                line-height: 1.5rem;
                a{
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                    color:#0000FF;
                }
            }
        }
        .footer-card{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 0px;
            gap: 16px;
            width: 100%;
            .historico{
                background-image: url(../../assets/img/icons/icon-comment.svg);
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 20px;
                a{
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 2.0rem;
                    letter-spacing: -0.24px;
                    color: var(--color-black);
                    &:hover{
                        color: var(--color-gray-600);
                    }
                }
            }
            .encaminhamento{
                background-image: url(../../assets/img/icons/icon-share.svg);
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 20px;
                a{
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 2.0rem;
                    letter-spacing: -0.24px;
                    color: var(--color-black);
                    &:hover{
                        color: var(--color-gray-600);
                    }
                }
            }
        }
   }
   .checked-card-caso{
    display: none;
        &:checked{
            &~ .conteudo-card-caso{
                .header-caso{
                    .btn-acoes{
                        label{
                            & .btn-view-card{
                                will-change: transform;
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                .imagem-destaque-card{
                    display: none;
                }
                .descricao-card-caso{
                    display: none;
                }
                .footer-card{
                    display: none;
                }
            }
        }
    }
}
