

.conteudo-busca-aluno{
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  gap: 5px;
  background-color: var(--color-white-600);
  border-radius: 3.2rem 3.2rem 0rem 0rem;
  max-width: 1246px;
  width: 100%;
  h3{
    color: #0F7BA6;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.2rem;
  }
  .section-filtro {
    padding: 20px 0 10px 0;
    .container{
      padding: 0px;
    }
  }
  .conteudo-search{
    background-color: rgba(255, 255, 255);
    border-radius: 122px;
    width: 100%;
    height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint($tablet){
      max-width: 100%;
    }
    @include breakpoint($celular){
      // display: none;
    }
    input[type=text]{
      background-color: rgba(255, 255, 255);
      border-radius: 32px;
      width: 80%;
      height: 100%;
      border: 0;
      outline: none;
      font-size: 1.5rem;
      line-height: 150%;
      color: var(--color-black-800);
      padding-left: 1.5rem;
      padding-right: 0.7rem;
    }
    button {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: 1px solid transparent;
      transition: border-color .3s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      text-indent: -9999px;
      &:hover {
        border-color: var(--color-black-800);
      }
    }
  }
}

.resultado-pesquisa{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.conteudo-turma{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  .header-turma{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    border-bottom: 0.5px solid #D0D0D0;
    .turma{
      color: #1E2340;
      font-size: 2.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .quantidade-turma{
      color: #1E2340;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .listagem-aluno-turma{
    display: flex;
    padding: 0rem 1rem;
    align-items: flex-start;
    gap: 1rem;
    .alunos{

    }
  }
}
