

#contrast{
  background-color: black !important;
  background-image: url(../../assets/img/backgroud/bg-body-dark.svg);
  p{
    color: #FFF;
  }
  // LOGIN
  .logo-conviva{
    img{
      filter: invert(0.1) brightness(100) sepia(3%) saturate(10000%) hue-rotate(176deg);
    }
  }
  .logo-gov-sp{
    img{
      // filter: brightness(100);
      // filter: contrast(0.01);
      filter: invert(1);
    }
  }

  //GOVBR
  div#topbarLink {
      background: #fff!important;
  }

  .govsp-portal img[src*="https://saopaulo.sp.gov.br/barra-govsp/img/logo-governo-do-estado-sp.png"] {
    background: url(https://saopaulo.sp.gov.br/barra-govsp/img/logo-governo-do-estado-sp-negativo-1.png) no-repeat!important;
    content: url(https://saopaulo.sp.gov.br/barra-govsp/img/logo-governo-do-estado-sp-negativo-1.png);
  }
  .govsp-social{
    color: #fff!important;
  }
  img.govsp-icon-social {
      filter: brightness(100);
  }
  img.govsp-acessibilidade {
      filter: brightness(100);
  }
  #govsp-topbarGlobal.blu-e {
    background-color: #000000;
  }
  #govsp-topbarGlobal.blu-e #topbarLink a {
      color: #111!important;
  }
  p.govsph-pasta {
    background: #fff!important;
    color: #111!important;
    width: 320px;
    padding-right: 130px;
    padding-top: 14px;
    padding-bottom: 0px;
    margin-top: -14px;
    height: 80px;
    background-repeat: no-repeat!important;
    background-position: right!important;
  }
  div#govsph-topbarGlobal div#topbarGlobal {
    border: 1px solid #fff;
  }
  .govsph-logo {
      background: url(https://saopaulo.sp.gov.br/barra-govsp/img/logo-sp-negativo.png) no-repeat!important;
  }
  .govsph-oneline {
      padding-top: 20px!important;
  }
  a.govsp-links-footer {
      color: #fff;
  }
  .govsp-link.digital {
    color: #ffffff;
  }

    //FOOTER GOVBR
    section#govsp-rodape{
      background-color: #000000 !important;
      .linha-botoes .btn-model{
        background: #222222;
      }
    }
  //FINAL GOVBR

  .nav-dropdown ~ label {
    color: #000000 !important;
  }

  .logo-brasao{
    img{
      filter: grayscale(1);
    }
  }

  .perfil{
    img{
      filter: invert(80%);
    }
  }
  .btn-logout{
    background-image: url(../../assets/img/icons/icon-logout-two-dark.png);
  }

  .btn_outline{
    background: black !important;
    &:hover{
      background-color: var(--color-blue)!important;
      color:  var(--color-white)!important;
  }
  }
  .login_plataforma_conviva{
    .form-login {
      background-color: #1b1b1b!important;
    }
  }

  .conteudo-search{
    background: black !important;
    border: 1px solid white !important;
    color: white !important;
    input[type=text]{
      border:none!important;
      background-color: transparent !important;
      color: white !important;
    }
  }
  header{
    background-color: #000000!important;
    box-shadow: 0px 6px 6px rgba(255, 254, 254, 9%)!important;
    // border-bottom: 0.4px solid #FFF;
    .container{
      .item-menu{
        .icon-nav{
          background-image: url(../../assets/img/icons/icon-btnNav-white.svg);
        }
      }
      .conteudo-perfil{
        .icon-notificacao{
          .notificacao{
            background-image: url(../../assets/img/icons/icon-bell-white.svg);
          }
        }
      }
    }
  }
  .sidebar-menu{
    background-color: #000000;
    box-shadow: 4px 0px 16px rgba(255, 255, 255, 0.25);
    nav{
      .identidade-menu{
        p{
          color: #FFF;
        }
      }
      .perfil-menu{
        .perfil{
        }
        .sidebar-menu-fechar{
          background-image: url(../../assets/img/icons/icon-close.svg);
        }
      }
      ul{
        &::-webkit-scrollbar-track {
          background-color: var(--light-mode-barra-scroll,rgba(14, 14, 14, 0.938));
        }
        &::-webkit-scrollbar {
          width: 6px;
          background-color: var(--light-mode-barra-scroll,rgba(14, 14, 14, 0.938));
        }
        &::-webkit-scrollbar-thumb {
          background: var(--light-mode-scroll, #5a5a5a);
          border-radius: 4px;
          border: 3px solid var(--light-mode-scroll, #5a5a5a);
        }
        li{
          .nav-dropdown ~ label{
            opacity: 1;
          }
          .nav-dropdown:checked ~ label {
            background-color: #635d5d;
            color: #ffffff !important;
          }
          a{
            color: #ffffff !important;
            background: #635d5d;
            &:hover{
              background: #FFF;
              box-shadow: 4px 0px 16px rgba(228, 228, 228, 0.1)!important;
              color: #000 !important;
            }
            &.active{
              background: #FFF;
              box-shadow: 4px 0px 16px rgba(228, 228, 228, 0.1)!important;
              color: #000 !important;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .card-stores{
    border:solid 0.5px #FFF;
  }



  .conteudo-principal{
    .container-conviva{
        .time-line{
            background: #222222!important;
        }
    }
  }
  .filtro-time-line{
    background: #222222!important;
  }



  .links-acesso-rapido{
    background: #181818!important;

    ul{
        li{
            a{
                background: #414040;
                color: #FFF;
                &:hover{
                    background: #878787;
                    color: #000000!important;
                }
            }
        }
    }
  }


  .card-timeline{
    box-shadow: none;
    background-color: #414040;
    .header-card{
      .perfil-aluno{
        span {
          background-color: #333333;
        }
        .info-aluno{
          .numero-chamado{
            color: #ffffff !important;
          }
          p{
            color: #ffffff!important;
            small{
              color: #ffffff!important;
            }
          }
        }
      }
    }
    .descricao-card{
      p {
        line-height: 2.1rem;
        color: #ffffff;
      }
    }
    .footer-card{

        .historico{
            background-image: url(../../assets/img/icons/icon-comment.svg)!important;
            background-repeat: no-repeat!important;
            background-position: left center!important;
            a{
              color: #ffffff;
            }
        }
        .encaminhamento{
            background-image: url(../../assets/img/icons/icon-share.svg)!important;
            background-repeat: no-repeat!important;
            background-position: left center!important;
            a{
              color: #ffffff;
            }
        }
    }
  }








  .header-chamado{
    .conteudo-search{
      background-color: #222222!important;
    }
  }

  .card-chamado{
    background-color: transparent!important;
    .photo-aluno{
      background-color: transparent!important;
    }
    &__info{
      background-color: transparent!important;
      .card-footer{
        background-color: transparent!important;
      }
    }
  }

  .conteudo-chamado{
    .header-perfil-chamado{
      background-color: transparent!important;
      .perfil-aluno-chamado{
        background-color: transparent!important;
        .perfil-aluno{
          background-color: transparent!important;
        }
        .infor-aluno-chamado{
          background-color: transparent!important;
          .nome-aluno{
            color: #ffffff;
          }
          .descricao{
            color: #ffffff;
          }
        }
      }
    }
    .tab-status{
      background-color: transparent!important;
      .opcoes-encaminhamentos{
        background-color: transparent!important;
        .encaminhamentos{
          background-color: transparent!important;
        }
      }
    }
  }

  .conteudo-perfil{
    .conteudo-casos{
      background-color: transparent!important;
      .box-casos{
        .number-casos{
          color: #000!important;
        }
        .legenda-casos{
          color: #000!important;
        }
      }
    }
    .escola-perfil{
      background-color: transparent!important;
      .row-escola{
        background-color: transparent!important;
      }
    }
    .dados-pessoais{
      background-color: transparent!important;
    }
  }
  .form-registro{
    .conteudo-textarea{
      label{
        background-color: transparent!important;
        color: #FFF;
      }
      input{
        background-color: black;
        color: #FFF;
      }
      textarea{
        background-color: black;
        color: #FFF;
      }
    }
    .conteudo-upload{
      label{
        background-color: black;
        color: #FFF;
      }
      input{
        background-color: black;
        color: #FFF;
      }
    }
    .conteudo-select{
      background-color: transparent!important;
      label{
        background-color: transparent!important;
        color: #FFF;
      }
      input{
        background-color: transparent;
        color: #FFF;
      }
      .input-date {
        &::-webkit-calendar-picker-indicator {
          cursor: pointer;
          filter: invert(0.1) brightness(100) sepia(3%) saturate(10000%) hue-rotate(176deg);
        }
        background-color: black;
        color: #FFF;
      }
      .ng-select .ng-select-container {
        background-color: #000000;
        color: #FFF;
      }
    }
    .conteudo-textarea{
      background-color: transparent!important;
    }
    .input-upload{
      background-color: transparent!important;
    }
    .container-btns{
      background-color: transparent!important;
    }
  }

  .conteudo-busca-aluno {
    background-color: #222222 !important;
  }
  .conteudo-busca-aluno{
    .section-filtro{
      // background-color: transparent!important;
      .grupo-filter{
        background-color: transparent!important;
        input{
          &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            filter: invert(0.1) brightness(100) sepia(3%) saturate(10000%) hue-rotate(176deg);
          }
        }
        label{
          background-color: transparent!important;
        }

      }
    }
  }
  .section-filtro{
    // background-color: transparent!important;
    .container{
      background-color: transparent!important;
    }
  }
  .resultado-pesquisa{
    background-color: transparent!important;
    .conteudo-turma{
      background-color: transparent!important;
      .header-turma{
        background-color: transparent!important;
        .turma{
          color: #ffffff;
          background-color: transparent!important;
        }
        .quantidade-turma{
          background-color: transparent!important;
        }
      }
      .listagem-aluno-turma{
        background-color: transparent!important;
      }
    }
  }

  .alunos{
    .nome-aluno {
      color: #d5d5d5;
    }
  }

  .conteudo-busca-aluno{
    h3 {
      color: #ffffff;
    }
  }


  .btn-alterar-perfil{
    background: #161616;
    p{
      color: var(--ttulo2, rgb(255 255 255 / 87%));
      b{
        color: #ffffff;
      }
    }
  }
  .icon-alterar-perfil{
    background-image: url(../../assets/img/icons/icon-alterar-perfil-dark.svg);
  }


  .section-breadcrumb{
    .container{
      a{
        color: #ffffff;
        &:first-child::before {
          background-image: url(../../assets/img/icons/icon-home-dark.svg);
        }
      }
    }
  }

  .section-saudacao{
    .container{
      p{
        color: #ffffff;
      }
    }
  }


  .grupo-filter, .grupo-filter-ano{
    p{
      b{
        color: #ffffff;
      }
    }
    .ng-select .ng-select-container{
      color: #ffffff;
      background-color: black;
    }
  }

  .section-filtro{
    .container{
      .btn-search-filtro{
        background-color: #3f3f3f;
        &:hover{
          background-color: #ffffff;
        }
      }
    }
  }

  //CALENDARIO
  .section-calendario{
    .container{
      .header-calendario{
        h2{
          color: var(----color-black-100, #ffffff);
        }
        .icone{
          &.voltar-mes{
            background-image: url(../../assets/img/icons/icon-seta-esquerda-dark.svg);
          }
          &.avancar-mes{
            background-image: url(../../assets/img/icons/icon-seta-direita-dark.svg);
          }
        }
      }
      .dias{
        .dia{
          background: rgb(71 71 71 / 50%);
          & .dia-semana{
            color: #ffffff;
          }
          .numero{
            color: #ffffff;
          }
          &.indisponivel{
            background-color: #909090;
            border-bottom: 4px solid #ffffff;
            & .dia-semana{
              color: #0e0e0e;
            }
            & .numero {
              color: #0e0e0e;
            }
          }
          &.dia-atual{
            border-bottom: 4px solid #0064FA;
            background-color: rgba(0, 100, 250, 63%);
          }
          &.dia-sem-ocorrencia{
            border-bottom: 4px solid #20C7A3;;
            background: rgba(32, 199, 163, 0.40);
          }
          &.dia-com-ocorrencia{
            border-bottom: 4px solid #EE5858;
            background: rgba(238, 88, 88, 63%);
          }
          &.dia-sem-marcacao{
            border-bottom: 4px solid #F7B100;
            background: rgba(247, 177, 0, 63%);
          }

        }
      }
    }
  }
.legenda-calendario{
  p{
    color: var(--Text-Title, #ffffff);
  }
  .conteudo-legenda{

  }
}
//FINAL CALENDARIO

.links-acesso-rapido{
  h3{
    color: #ffffff;
  }
  h5{
    color: #f5f5f5;
  }
}

.sidebar-alunos-recorrentes h3 {
  color: #ffffff;
}

.aluno{
  .nome-aluno {
    color: #b7b7b7;
  }
}
.alunos{
  .img-aluno {
    span {
      background-color: #333333;
    }
  }
}
.conteudo-principal{
  .container-conviva{
    .time-line{
      h2 {
        color: #ffffff;
      }
    }
  }
}
.filtro-time-line{
  label {
    color: #ffffff;
  }
}

.btn-acoes{
  // filter: brightness(100);
  .btn-view-card{
    filter: brightness(100);
  }
  .opcoes-card{
    background-color: #414040!important;
    a{
      color: #ffffff;
      &:hover{
        color: #00f3ff;
      }
    }
  }
}

.numero-chamado-header {
  color: #ffffff;
}

.nome-label-detalhes{
  color: #ffffff;
}


.page-detalhes-chamado{
  .header-detalhes-chamado{
    p {
      color: #d7d7d7;
    }
  }
  .perfil-aluno-envolvidos{
    border-bottom: 1px solid rgb(255 255 255 / 82%);
  }
}
.titulo-envolvidos-header{
  color: #ffffff;
}

.conteudo-chamado {

  .header-perfil-chamado{

    .perfil-aluno-chamado {

      .perfil-aluno{
        span {
          background-color: #393939;
        }
      }
    }
  }
}

.conteudo-do-chamado-chat{
  .container-text-historico{
    .text-historico{
      background: #101010;
    }
  }
}
.input-upload{
  form {
    textarea {
      border: 1px solid #141414 !important;
      background-color: black;
      color: #FFF;
    }
    input[type=file] {
      border: 1px solid #000000 !important;
      background: #000000;
      color: #FFF;
    }
  }
}

.modal-content {
  background-color: #292929;
}
.titulo-page{
  color: #fdfdfd;
}

.descricao-historico-add-info{
  background-color: #000000;
}
.ds-chamado{
  textarea{
    border: 1px solid #ccc;
    background-color: black;
    color: #FFF;
  }
}
.text-area-new-info {
  border: 1px solid #C4C4C4 !important;
  background-color: black;
  color: #FFF;
}


.identificacao-perfil {
  .nome-perfil {
    color: #FFF;
  }
  .cargo-perfil {
    color: #FFF;
  }
}

.conteudo-perfil{
  .conteudo-casos{
    .box-casos{
      &.criados {
        background-color: #aedfed;
        .number-casos {
          color: #222222;
        }
        .legenda-casos {
          color: #222222;
          font-weight: bold!important;
        }
      }

      &.abertos {
        border: 0.5px solid var(--color-red);
        background-color: #f5bfbf;
        color: #FFF;
        .number-casos {
          color: #222222;
        }
        .legenda-casos {
          color: #222222;
          font-weight: bold!important;
        }
      }

      &.encerrados {
        background-color: #8ff7b5;
        .number-casos {
          color: #222222;
        }
        .legenda-casos {
          color: #222222;
          font-weight: bold!important;
        }
      }

    }
  }
}


.grid-chamado-perfil-usuario{
  h4{
    color: var(----color-blue, #ffffff);
  }
  .grid-titulo-com-pesquisa{
    h3{
      color: var(----color-blue, #ffffff);
    }
    .pesquisa-chamado{
      input{
        background: var(----color-white, #000000);
        color: var(----color-blue, #ffffff);
      }
    }
  }
  .grid-listagem-cards{
    .cards-chamados-perfil{
      background-color: rgb(65 64 64);

      .icon-card-newblank{
        img{
          filter: brightness(100);
        }
      }
    }
  }
}

.sidebar-registro{
  .box-sidebar-person{
    h3 {
      color: #ffffff;
    }
 }
}
.grupo-filter-modal{
  input {
    color: #FFF;
    background-color: transparent !important;

  }
  .ng-select.ng-select-single .ng-select-container {
    background: black;
    color: #FFF;
  }
}
.conteudo-textarea{
  input {
    color: #FFF;
    background-color: transparent !important;
  }
  .ng-select.ng-select-single .ng-select-container {
    height: 36px;
    background: black;
    color: white;
  }
}

.btn-add-person{
  .btn-gg{
    img {
      background-color: #7f7f7f;
    }
  }
}

.grupo-filter-gravidade{
  label{
    color: #FFF;
  }
  input {
    color: #FFF;
    background-color: transparent !important;
  }
  .ng-select.ng-select-single .ng-select-container {
    background: black;
    color: white;
  }
}
.grupo-filter-status{
  label{
    color: #FFF;
  }
  input {
    color: #FFF;
    background-color: transparent !important;
  }
  .ng-select.ng-select-single .ng-select-container {
    background: black;
    color: white;
  }
}
.grupo-filter{
  input {
    color: #FFF;
    background-color: transparent !important;
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      filter: invert(0.1) brightness(100) sepia(3%) saturate(10000%) hue-rotate(176deg);
    }
  }
}
label{
  color: #FFF;
}
.grupo-filter-sla{
  label{
    color: #FFF;
  }
  input {
    color: #FFF;
    background-color: transparent !important;
  }
  .ng-select.ng-select-single .ng-select-container {
    background: black;
    color: white;
  }
}

.filtro-time-line{
  input {
    color: #FFF;
    background-color: transparent !important;
  }
  .ng-select.ng-select-single .ng-select-container {
    background: black;
    color: white;
  }
}

.conteudo-chamado {
  background-color: #161616;
}
.grupo-filter-full{
  input {
    color: #FFF;
    background-color: transparent !important;
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      filter: invert(0.1) brightness(100) sepia(3%) saturate(10000%) hue-rotate(176deg);
    }
  }
  .ng-select.ng-select-single .ng-select-container {
    background: black;
    color: white;
  }
}
.section-filtro-visitas{
  .container{
    h2{
      color: var(----color-black-100, #ffffff);
    }
  }
}

.form-vista-psicologo {
  background-color: #292a2b;
}

.periodo{
  p{
    b{
      color: #ffffff;
    }
  }
  .periodo-input{
    input{
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        filter: invert(0.1) brightness(100) sepia(3%) saturate(10000%) hue-rotate(176deg);
      }
      color: #ffffff;
      background-color: #000000;
    }
  }
}

.sub-titulo-dash{
  color: #ffffff;
}
.table-buttons .filtros select, .table-buttons .filtros input {
  color: #ffffff;
  background-color: #000000;
}
.impressao {
  background: #e1e1e1;
  font-weight: bold;
  color: #242424;
}
.excel {
  background: #b8ff93;
  color: #008000;
  font-weight: bold;
}
.pdf {
  font-weight: bold;
  background: #f79696;
  color: #ffffff;
  border: solid #ec5353 1px;
}

.tabulator{
  background-color: #000000;
  .tabulator-header {
    border-bottom: 2px solid #000000 !important;
    border-top: 1px solid #000000 !important;
  }
  .tabulator-col-resize-handle {
    background-color: #000000;
  }
  .tabulator-footer{
    .tabulator-footer-contents {
      background-color: #000000;
    }
  }
}
div.dt-buttons > .dt-button{
  border: 1px solid rgb(255 255 255 / 30%);
  color: #FFF;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled{
  color: #FFF !important;
}
.dataTables_wrapper .dataTables_paginate .ellipsis {
  color: #FFF;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #FFF !important;
}


.grid-datatable table thead tr th {
  background: #636363;
}

.tabulator-col.tabulator-sortable.tabulator-col-sorter-element {
  background-color: #636363 !important;
  color: #fff;
}


.section-table{
  .container{
    table{
      caption{
        color: #FFF;
      }
      thead{
        background-color: #636363 !important;
        color: #fff;
      }
    }

  }
}



.grupo-filter-full{
  input {
    color: #FFF;
    background-color: transparent !important;
  }
  textarea {
    background: black;
    color: white;
  }
}
.header-page-relatorio{
  .container{
    h2{
      color: #ffffff;
    }
  }
}

.conteudo-relatorio{
  .container{
    .grup-input{
      input {
        color: #FFF;
        background-color: transparent !important;
      }
      .ng-select.ng-select-single .ng-select-container {
        background: black;
        color: white;
      }
    }
  }
}


.btn_terciario {
  background: rgb(255 255 255 / 88%);
  color: var(----color-blue, #000000);
}
.btn_outline {
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: black !important;
}
.btn_primary{
  background-color: #555555;
  color: var(--color-white);
}
.btn_primeiro {
  border: 1px solid #555555;
  background-color: #555555;
  color: var(--color-white);
}
.btn_segundario {
  border: 1px solid #ffffff;
  background: #161616;
  color: var(----color-blue, #ffffff);
  &:hover{
    background: #252525;
  }
}

.sidebar-chamado{
    h2{
      color: #ffffff;
    }
  }
  .chamado-historico ul li p small {
    color: #ffffff;
  }
  .descricao-modal-excluir{
    span{
      color: #ffffff;
    }
  }

  .title-ocorrencia{
    color: #ffffff;
  }
  .dataTables_wrapper .dataTables_info {
    color: #FFF !important;
  }



  .range-slider > input:nth-of-type(1) + output::after {
    color: #FFF;
  }

  .conteudo-envolvidos {
    color: #FFF;
    h3{
      color: #FFF;
    }
  }

  .grid-datatable table tbody tr:nth-child(even) {
    background: var(----color-white-600, #181818);
  }
  .grid-datatable table tbody tr:nth-child(odd) {
    background: var(----color-gray-100, #000000);
  }
  .grid-datatable table tbody tr td {
    color: #ffffff;
  }
  .grid-datatable table tbody tr td a i {
    color: #e5e5e5;
  }
  .icons-star{
    img{
      filter: brightness(10);
    }
  }

  .tabulator-row.tabulator-selectable.tabulator-row-odd {
    background: var(----color-gray-100, #181818);
    border-bottom: 1.5px solid #181818;
  }
  .tabulator-row.tabulator-selectable.tabulator-row-even {
    background: var(----color-white-600, #000000);
    border-bottom: 1.5px solid #000000;
  }
  .tabulator-row .tabulator-cell {
    color: #ffffff;
    font-size: 1.2rem;
  }

  .grid-datatable
  table
  tbody
  tr
  td
  {
    border: 2px solid #000000 !important;
  }
  .grid-datatable
   table
   thead
   tr
   th
   {
    border: 2px solid #000000;
  }

  .descricao-modal-agendamento
 p
 strong{
    color: #e5e5e5;
  }
  .container-modal i {
    color: #959595;
  }
  .container-modal strong {
    color: var(----color-blue, #ffffff);
  }

  .inputs-form strong {
    color: #ffffff;
  }

  .input_style {
    background: #000000;
    color: #FFF;
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      filter: invert(0.1) brightness(100) sepia(3%) saturate(10000%) hue-rotate(176deg);
    }
  }

  .ng-select .ng-select-container {
    background-color: #000;
    color: #FFF;
  }

  .perfil-aluno-chamado {
    background: var(----color-blue-100, #393939);
  }
  .form-aprovacao-diretor {
    background-color: #161616;
  }
  .titulo-aprovacao[_ngcontent-ng-c2785181064] {
    color: var(----color-black-200, var(----color-black-200, #ffffff));
    background-color: #161616;
    box-shadow: 1px -1px 0px 0px rgb(45 48 49);
  }

  .textarea-container
  textarea
  {
    background-color: #000;
    color: #FFFFFF;
  }
  small {
    color: #FFF;
  }

  .filtro-ocorrencia {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid #ccc;
    background-color: #000;
    color: #FFF;
  }
  .conteudo-chamado .header-perfil-chamado label .opcoes-card {
    background-color: #414040!important;
  }
  .conteudo-chamado .header-perfil-chamado label .opcoes-card a {
    color: #ffffff;
  }

  .card-chamado .photo-aluno span {
    background-color: #393939;
  }
  .conteudo-chamado .tab-status .status.ativo {
    filter: brightness(100);
  }

  .close-modal{
    a{
      i{
        color: #ffffff;
      }
    }
  }

  .identificacao-perfil .iniciais-perfil span {
    background-color: #393939;
  }
  .btn-action {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: #292929;
    &:hover{
      background-color: #878787;
    }
  }

  .conteudo-perfil .escola-perfil h3 {
    color: #ffffff;
  }
  .conteudo-perfil .dados-pessoais h3 {
    color: #ffffff;
  }

  .conteudo-perfil .escola-perfil .row-escola img{
    filter: brightness(100);
  }

  .page-link{
    color: rgb(255 255 255 / 75%);
    background-color: #606060;
    border-color: #606060;
    &:hover{
      color: var(--bs-pagination-active-color);
      background-color: #414040;
      border-color: #414040;
    }
  }
  .page-link.active, .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #414040;
    border-color: #414040;
  }
  .page-link.disabled, .disabled > .page-link {
    color: rgb(255 255 255 / 75%);
    pointer-events: none;
    background-color: #606060;
    border-color: #606060;
  }
  .section-table
   .container
   table
   thead
    th{
      border: 2px solid #000000;
    }
  .section-table
   .container
   table
   tbody
   tr{
    &:nth-child(odd) {
      background: var(----color-gray-100, #000000);
    }
    &:nth-child(even) {
      background: var(----color-white-600, #181818);
    }
    td{
      border: 2px solid #000000;
      color: #ffffff;
    }
   }

  .btn-add-person {
    border: solid 2px #161616;
  }
  .btn-add-person.btn-gg img {
    background-color: #161616;
  }
  .titulo-aprovacao {
    color: var(----color-black-200, var(----color-black-200, #ffffff));
    background-color: #161616;
  }
  .grid-datatable-simple
   th{
    background-color: #000000;
    color: white;
  }
  .grid-datatable-simple th, .grid-datatable-simple td {
    color: #FFF;
  }
  .header-modal{
    a{
      i{
        color: #ffffff;
      }
    }
  }
  .sidebar-notificacao {
    background-color: rgb(77 77 77 / 94%);
  }
  .title h2 {
    color: #FFF;
  }
  .sidebar-notificacao .notificacao-fechar {
    filter: invert(1);
  }



  .modal-notificacao-alert
  .modal
  .modal-conteudo
  ul
  {
    color: #FFF;
  }

  .modal-notificacao-alert
  .modal
  .modal-header
  .btn-close-modal
  {
    filter: invert(1);
  }


  .card-notification {
    background-color: #343434;
    border-bottom: 1px solid #FFF;
    .btn-acoes .btn-opcoes-card {
      filter: brightness(100);
    }
  }
  .perfil-aluno span {
    background-color: #636363;
  }


  .btn-ler-notificacoes {
    background-color: #00af56;
    font-weight: 600;
    color: white;
  }

} //FINAL CONTRAST
