
.container-btns-modal{
  display: flex;
  justify-content: space-between;
}

.modal-alterar-foto-perfil{
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  h3{
    color: #0F7BA6;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.2rem;
    margin-bottom: 15px;
  }
  form{
    display: flex;
    flex-direction: column;
    .btn_primary{
      margin-top: 35px;
    }
  }
}

.modal-alterar-foto-perfil{
  h3{
    color: #0F7BA6;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.2rem;
    margin-bottom: 15px;
  }
  .form-label {
    font-size: 16px;
    font-weight: 700;
    align-items: center;
}
.input-group-display-password {
  display: flex;
  margin: 8px 0 25px;
}
  .form-input{
    display: block;
    padding: 15px;
    width: 100%;
    color: #747c81;
    border: 1.5px solid #e3e9ec;
    border-radius: 2px;
    background-color: #fbfeff;
  }
  .btn-display {
    width: 40px;
    border: 1.5px solid #e3e9ec;
    border-left: unset;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.password-rules-title {
  color: #747c81;
  font-weight: 700;
  margin-bottom: 10px;
}
.--password-show {
  background-image: url(../../assets/img/icons/action-icon-hide.svg);
}
.password-rules-list {
  margin-bottom: 15px;
  li {
    color: #747c81;
    display: grid;
    align-items: center;
    grid-template-columns: 15px 1fr;
    grid-column-gap: 10px;
    &:before {
      content: "";
      display: inline-block;
      background: url(../../assets/img/icons/icon-rule-incomplete.svg) no-repeat 50%;
      background-size: contain;
      width: 12px;
      height: 12px;
      margin-right: 10px;
  }
}
}
}
